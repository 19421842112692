<!-- Sources -->
<app-source-selection
    [selectedFailoverSources]="controlledValue || []"
    (selectedFailoverSourcesChange)="ngControl?.control?.setValue($event)"
    [formSubmitted]="isParentFormSubmitted"
    [requireSlate]="false"
    [maxSourceSelection]="maxSourceSelect"
    [isFailoverForm]="isFailoverForm"
></app-source-selection>
<app-input-errors-indicator *ngIf="invalid" [errors]="ngControl.errors" [fieldName]="'SOURCES' | translate" [invalid]="invalid"></app-input-errors-indicator>
