import { Component, Input } from "@angular/core";
import { FormControlDirective } from "@angular/forms";
import { Source } from "src/app/models/shared";
import { CustomControlValueAccessor } from "../custom-control-value-accessor/custom-control-value-accessor";

type SourceFieldSelection = {
    source: Source;
    priority: number;
    backup?: boolean;
    min_bitrate: number;
}[];

@Component({
    selector: "app-source-field-selection",
    templateUrl: "./source-field-selection.component.html"
})
export class SourceFieldSelectionComponent extends CustomControlValueAccessor<SourceFieldSelection> {
    @Input() isParentFormSubmitted: boolean;
    @Input() maxSourceSelect: number;
    @Input() isFailoverForm?: boolean;

    disabled = this.ngControl.disabled;
    controlledValue: SourceFieldSelection = this.ngControl.viewModel || [];

    constructor(public ngControl: FormControlDirective) {
        super(ngControl);
    }
}
