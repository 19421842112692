<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" [ngClass]="{ loading: saving }">
    <div class="modal-header">
        <h3 class="modal-title">{{ "SWITCH_TARGET_CHANNEL" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- Targets -->
        <div class="form-group">
            <label for="targets">
                {{ "TARGETS" | translate }}
            </label>
            <ul class="list-style-none mimic-tags">
                <li *ngFor="let target of targets">
                    <zx-target *ngIf="target" [model]="target" [showLink]="true"></zx-target>
                    <span *ngIf="target._frontData.saving && target._frontData.processing === 'start'">
                        &nbsp;-&nbsp;<span class="spinner-border spinner-border-sm ms-1 me-1" role="status" aria-hidden="true"></span
                        >{{ "PROCESSING" | translate }}...
                    </span>
                    <span *ngIf="!target._frontData.saving && !target._frontData.hasError && target._frontData.processing === 'end'"
                        >&nbsp;-&nbsp;<span>{{ "UPDATED" | translate | lowercase }}</span>
                    </span>
                    <span
                        class="status-error"
                        title="{{ 'ERROR' | translate }}:&nbsp;{{ target._frontData.error.message }}"
                        *ngIf="!target._frontData.saving && target._frontData.hasError && target._frontData.processing === 'end'"
                        >&nbsp;-&nbsp;{{ "ERROR" | translate }}:&nbsp;{{ target._frontData.error.message }}</span
                    >
                </li>
            </ul>
        </div>
        <!-- Channel Type -->
        <fieldset class="form-group" *ngIf="(canMediaconnect || canMedialive) && state !== 'done'">
            <legend for="type">{{ "CHANNEL_TYPE" | translate }}</legend>
            <mat-button-toggle-group name="type" aria-label="type" [(ngModel)]="type" (change)="typeChanged()">
                <mat-button-toggle [value]="'delivery'">{{ "ZM_CHANNEL" | translate }}</mat-button-toggle>
                <mat-button-toggle [value]="'mediaconnect'" *ngIf="canMediaconnect">{{ "AWS_MEDIACONNECT_FLOW" | translate }}</mat-button-toggle>
                <mat-button-toggle [value]="'medialive'" *ngIf="canMedialive">{{ "MEDIALIVE" | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </fieldset>
        <!-- Channel Select -->
        <div class="form-group mb-0" *ngIf="state !== 'done'">
            <!-- Channel Table -->
            <div>
                <div class="lh-10">
                    <label for="channel"> {{ "CHANNEL_OPTIONAL" | translate }} </label><span *ngIf="selectedRows[0]">&nbsp;&nbsp;-&nbsp;&nbsp;</span
                    ><zx-adaptive *ngIf="type === 'adaptive' && selectedRows[0]" [model]="selectedRows[0]" [showLink]="true" [showCluster]="false"></zx-adaptive
                    ><zx-delivery *ngIf="type === 'delivery' && selectedRows[0]" [model]="selectedRows[0]" [showLink]="true" [showCluster]="false"></zx-delivery
                    ><zx-mediaconnect-flow
                        *ngIf="type === 'mediaconnect' && selectedRows[0]"
                        [model]="selectedRows[0]"
                        [showLink]="true"
                        [showCluster]="false"
                    ></zx-mediaconnect-flow
                    ><zx-medialive-channel
                        *ngIf="type === 'medialive' && selectedRows[0]"
                        [model]="selectedRows[0]"
                        [showLink]="true"
                        [showCluster]="false"
                    ></zx-medialive-channel
                    ><fa-icon class="ms-1 cursor-pointer" icon="times-circle" size="md" *ngIf="selectedRows[0]" (click)="selectedRows = []"></fa-icon>
                </div>
                <!-- Table -->
                <app-table-list
                    [tableName]="'switch-target-channel'"
                    [displayTableName]="'CHANNELS' | translate"
                    [data]="allChannels | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [showSelectionCheckbox]="false"
                    [showReport]="false"
                    [autoRows]="false"
                    [useAsInput]="true"
                    [refreshing]="loadingChannels"
                    (currentSortDirection)="onSort($event)"
                    (rowSelected)="selectRow($event)"
                    [(selectedRows)]="selectedRows"
                >
                </app-table-list>
            </div>

            <!-- Source preference -->
            <div class="form-group mt-3" *ngIf="type === 'delivery'">
                <label for="source_preference">
                    {{ "SOURCE_PREFERENCE" | translate }}
                </label>
                <zx-delivery-channel-source-select
                    id="preferred_source"
                    name="preferred_source"
                    [channel_id]="deliveryChannelID"
                    [(model)]="preferredSourceID"
                    [disabled]="!deliveryChannelID"
                    [allowKeepSelecton]="targets.length > 1"
                    [allowPreferenceSelection]="havePullTargets"
                >
                </zx-delivery-channel-source-select>
            </div>
            <!-- Transcoding Profile -->
            <div class="form-group mt-2" *ngIf="type === 'medialive'">
                <label for="transcodingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                    >{{ "TRANSCODING_PROFILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                    ><fa-icon icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
                    ><ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template></label
                >
                <zx-transcoding-profile-select
                    name="transcodingProfile"
                    [(model)]="transcodeProfile"
                    required="medialiveChannelID"
                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                ></zx-transcoding-profile-select>
                <div *ngIf="form.controls.transcodingProfile?.invalid" class="invalid-feedback">
                    <div *ngIf="form.controls.transcodingProfile?.errors.required">{{ "PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                </div>
            </div>
        </div>
        <div *ngIf="canReuseBX && type === 'delivery' && state !== 'done' && haveAssignedTargets" class="form-check">
            <input type="checkbox" class="form-check-input" id="content_analysis" name="content_analysis" [(ngModel)]="forceSameBX" />
            <label class="form-check-label" for="content_analysis">{{ "FORCE_SAME_BX" | translate }} </label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" [disabled]="saving" (click)="activeModal.close()" *ngIf="state !== 'done'">
            <fa-icon icon="times"></fa-icon>
            {{ "CANCEL" | translate }}
        </button>
        <button type="button" class="btn btn-primary" ngbAutofocus [disabled]="saving" zmid="form-submit" type="submit" *ngIf="state !== 'done'">
            <fa-icon icon="random" size="sm"></fa-icon>
            {{ "SWITCH" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
        <button type="button" class="btn btn-secondary" [disabled]="saving" (click)="activeModal.close()" *ngIf="state === 'done'">
            {{ "CLOSE" | translate }}
        </button>
    </div>
</form>
