import { ComponentRef, Injectable, inject } from "@angular/core";
import * as _ from "lodash";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { KeyMap, SomeZixiObject, ZixiPlus } from "src/app/models/shared";
import { AnyTarget, PublishingTarget, RistTarget, TargetPidMappingTypes, ZixiPullTarget } from "../channels/channel";
import { TranslateService } from "@ngx-translate/core";
import { TagsService } from "../configuration/tags/tags.service";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { TargetsService } from "./targets.service";
import { ZxBroadcasterComponent } from "src/app/components/shared/zx-broadcaster/zx-broadcaster.component";
import { assignComponentsBroadcastAdapter } from "src/app/components/shared/zx-broadcaster/zx-broadcaster.table-adapter";
import { ZxTargetTargetsColumnComponent } from "src/app/components/shared/table-list/tables-components/zx-target-targets-column/zx-target-targets-column.component";
import { assignComponentsTargetTargetsAdapter } from "src/app/components/shared/table-list/tables-components/zx-target-targets-column/zx-target-targets-column.table-adapter";
import { ZxNumericColComponent } from "src/app/components/shared/zx-numeric-col/zx-numeric-col.component";
import { DecimalPipe } from "@angular/common";
import { UptimePipe } from "src/app/pipes/uptime.pipe";
import { ZxTargetChannelColumnComponent } from "src/app/components/shared/table-list/tables-components/zx-target-channel-column/zx-target-channel-column.component";
import { assignComponentsTargetChannelsColumnAdapter } from "src/app/components/shared/table-list/tables-components/zx-target-channel-column/zx-target-channels-column.table-adapter";
import { ZxChannelTypeComponent } from "src/app/components/shared/zx-channel-type/zx-channel-type.component";
import { ZxTagsListComponent } from "src/app/components/shared/zx-tags-list/zx-tags-list.component";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";

export enum TargetsColumnsDefTypes {
    NAME = "name",
    STATUS = "status",
    ERROR = "error",
    EVENT = "message",
    EXTERNAL_ID = "external_id",
    BROADCASTER = "active_broadcaster",
    TYPE = "type",
    TARGET = "target",
    CLIENT_IP = "client_ip",
    BITRATE = "bitrate",
    UP_TIME = "up_time",
    CHANNEL = "channel",
    CHANNEL_TYPE = "channel_type",
    ALTERNATIVE = "alternative",
    TAGS = "access_tags",
    PRIORITY = "priority",
    MUTED = "muted",
    PID_MAPPING = "pid_mapping",
    BILLING_CODE = "billing_code"
}

type ColDefinition = TableSchema<KeyMap<AnyTarget>>;
type CustomFields = Partial<{ width?: number; visible?: boolean } | undefined>;
type Overrides = Partial<Record<TargetsColumnsDefTypes, CustomFields>>;
const customizeColumn = (fields: CustomFields): CustomFields => {
    return _.pick(fields, ["width", "visible"]) as CustomFields;
};

@Injectable({
    providedIn: "root"
})
export class TargetsDefinitions {
    private translate = inject(TranslateService);
    private statusTextPipe = inject(StatusTextPipe);
    private tagsService = inject(TagsService);
    private targetsService = inject(TargetsService);
    private decimalPipe = inject(DecimalPipe);
    private uptimePipe = inject(UptimePipe);

    _sortDirection: string;

    set currentTableSortDirection(value: string) {
        this._sortDirection = value;
    }

    get currentTableSortDirection(): string {
        return this._sortDirection;
    }

    getTargetColumnTextByRow(row: AnyTarget) {
        let textValue = "";
        if (!row.pull) {
            textValue += row.output_target;
        } else {
            const pullTarget = row.target as ZixiPullTarget;
            if (pullTarget.receiver_id) textValue += pullTarget.receiver.name;
            if (pullTarget.broadcaster_id) textValue += pullTarget.broadcaster.name;
            if ((pullTarget.receiver_id || pullTarget.broadcaster_id) && pullTarget.output_id)
                textValue += pullTarget.output_name;
            if (!pullTarget.receiver_id && !pullTarget.broadcaster_id) textValue += pullTarget.receiver_name;
        }
        return textValue;
    }

    getTableColumnsSchema(overrides?: Overrides): ColDefinition[] {
        return [
            {
                header: this.translate.instant("NAME"),
                columnDef: TargetsColumnsDefTypes.NAME,
                visible: true,
                width: 160,
                sticky: 1,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                    row => row.target.name,
                    row => row.target.name,
                    () => true
                ),
                sortBy: row => row.target.name,
                textValue: row => row.target.name,
                valueToExport: row => row.target.name,
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.name)
            },
            {
                header: this.translate.instant("STATUS"),
                columnDef: TargetsColumnsDefTypes.STATUS,
                width: 160,
                visible: true,
                component: ZxStatusFullComponent,
                assignComponentsInputs: assignComponentsStatusInputsFactory<KeyMap<AnyTarget>>({
                    lockIconTextCallBack: row => this.targetsService.DTLSNote(row),
                    modelCallBack: row => row.target as unknown as Partial<SomeZixiObject>,
                    showOtherIcons: true
                }),
                textValue: row => this.translate.instant(this.statusTextPipe.transform(row.target)),
                sortBy: row =>
                    this.currentTableSortDirection === "asc"
                        ? (row.target as unknown as ZixiPlus)._sortData.sortableStatusAsc
                        : (row.target as unknown as ZixiPlus)._sortData.sortableStatusDesc,
                valueToExport: row => this.translate.instant(this.statusTextPipe.transform(row.target)),
                columnFilterType: ColumnFilterType.SELECT,
                columnFilterValue: row => this.translate.instant(this.statusTextPipe.simpleTransform(row.target)),
                columnSelectOptions: ["Ok", "Warning", "Error", "Other"],
                ...customizeColumn(overrides?.status)
            },
            {
                header: this.translate.instant("ERROR"),
                columnDef: TargetsColumnsDefTypes.ERROR,
                hideFromColumnChooser: true,
                width: 100,
                visible: false,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                    row => row.target.activeStates?.map(as => as.short_message)?.join(",") ?? "",
                    row => row.target.activeStates?.map(as => as.short_message)?.join(",") ?? "",
                    () => true
                ),
                textValue: row => row.target.activeStates?.map(as => as.short_message)?.join(",") ?? "",
                valueToExport: row => row.target.activeStates?.map(as => as.short_message)?.join(",") ?? "",
                ...customizeColumn(overrides?.error)
            },
            {
                header: this.translate.instant("EVENT"),
                columnDef: TargetsColumnsDefTypes.EVENT,
                width: 100,
                visible: false,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                    row => row.target.activeStates?.map(as => as.message)?.join(",") ?? "-",
                    row => row.target.activeStates?.map(as => as.message)?.join(",") ?? "-",
                    () => true
                ),
                textValue: row => row.target.activeStates?.map(as => as.message)?.join(",") ?? "-",
                valueToExport: row => row.target.activeStates?.map(as => as.message)?.join(",") ?? "",
                sortBy: row => row.target.activeStates?.map(as => as.message)?.join(",") ?? "-",
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.message)
            },
            {
                header: this.translate.instant("EXTERNAL_ID"),
                columnDef: TargetsColumnsDefTypes.EXTERNAL_ID,
                width: 100,
                visible: false,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory(
                    row => (row.target as RistTarget).external_id ?? "",
                    row => (row.target as RistTarget).external_id ?? "",
                    row => !!(row.target as RistTarget).external_id
                ),
                valueToExport: row => (row.target as RistTarget).external_id ?? "",
                textValue: row => (row.target as RistTarget).external_id ?? "",
                sortBy: row => (row.target as RistTarget).external_id ?? "",
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.external_id)
            },
            {
                header: this.translate.instant("BROADCASTER"),
                columnDef: TargetsColumnsDefTypes.BROADCASTER,
                width: 160,
                visible: true,
                component: ZxBroadcasterComponent,
                assignComponentsInputs: assignComponentsBroadcastAdapter,
                textValue: row => row.target.status?.active_broadcaster?.name ?? "-",
                sortBy: row => row.target.status?.active_broadcaster?.name ?? "-",
                valueToExport: row =>
                    row.type === "pull"
                        ? row.target.status?.active_broadcasters[0]?.name
                        : row.target.status?.active_broadcaster?.name ?? "",
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.active_broadcaster)
            },
            {
                header: this.translate.instant("TYPE"),
                columnDef: TargetsColumnsDefTypes.TYPE,
                width: 140,
                visible: true,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<AnyTarget>(
                    row => this.translate.instant(row.type_name.toUpperCase()),
                    row => this.translate.instant(row.type_name.toUpperCase()),
                    () => true
                ),
                textValue: row => this.translate.instant(row.type_name.toUpperCase()),
                sortBy: row => this.translate.instant(row.type_name.toUpperCase()),
                valueToExport: row => this.translate.instant(row.type_name),
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.type)
            },
            {
                header: this.translate.instant("TARGET"),
                columnDef: TargetsColumnsDefTypes.TARGET,
                width: 160,
                visible: true,
                component: ZxTargetTargetsColumnComponent,
                assignComponentsInputs: assignComponentsTargetTargetsAdapter,
                textValue: row => this.getTargetColumnTextByRow(row),
                sortBy: row => this.getTargetColumnTextByRow(row),
                valueToExport: row => {
                    if (!(row.pull && row.target instanceof ZixiPullTarget)) return row.output_target;
                    let text = "";
                    if (row.target.receiver_id) text += row.target.receiver.name;
                    if (row.target.broadcaster_id)
                        text +=
                            row.target.broadcaster.name +
                            (row.target.broadcaster.broadcaster_cluster.name
                                ? " @ " + row.target.broadcaster.broadcaster_cluster.name
                                : "");
                    if ((row.target.receiver_id || row.target.broadcaster_id) && row.target.output_id)
                        text += " / " + row.target.output_name;
                    if (!row.target.receiver_id && !row.target.broadcaster_id) text += row.target.receiver_name;
                    return text;
                },
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.target)
            },
            {
                header: this.translate.instant("CLIENT_IP"),
                columnDef: TargetsColumnsDefTypes.CLIENT_IP,
                width: 120,
                visible: true,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                    row => (row.target.status?.ip && row.target.status?.ip !== "" ? row.target.status?.ip : "-"),
                    row => (row.target.status?.ip && row.target.status?.ip !== "" ? row.target.status?.ip : "-"),
                    () => true
                ),
                sortBy: row => (row.target.status?.ip && row.target.status?.ip !== "" ? row.target.status?.ip : "-"),
                textValue: row => (row.target.status?.ip && row.target.status?.ip !== "" ? row.target.status?.ip : "-"),
                valueToExport: row =>
                    row.target.status?.ip && row.target.status?.ip !== "" ? row.target.status?.ip : "-",
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.client_ip)
            },
            {
                header: this.translate.instant("BITRATE"),
                columnDef: TargetsColumnsDefTypes.BITRATE,
                width: 100,
                align: "right",
                visible: true,
                component: ZxNumericColComponent,
                assignComponentsInputs: (
                    bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                    row: KeyMap<AnyTarget>,
                    searchTerm: string[]
                ) => {
                    const bitrateCompRef = bitrateComponentRef.instance;
                    const props = {
                        number: this.decimalPipe.transform(
                            (row.target.status?.stats?.net_bitrate ?? 0) / 1000.0,
                            "1.0-0"
                        ),
                        unit: "kbps",
                        searchTerm: searchTerm
                    };
                    for (const key in props) {
                        const value = props[key];
                        bitrateCompRef[key] = value;
                    }
                },
                textValue: row => {
                    const title = this.decimalPipe.transform(
                        (row.target.status?.stats?.net_bitrate ?? 0) / 1000.0,
                        "1.0-0"
                    );
                    return title ? `${title}` : "0";
                },
                sortBy: row => row.target.status?.stats?.net_bitrate,
                valueToExport: row => (row.target.status?.stats?.net_bitrate ?? 0) / 1000.0,
                columnFilterType: ColumnFilterType.NUMBER,
                columnFilterValue: row => (row.target.status?.stats?.net_bitrate ?? 0) / 1000.0,
                ...customizeColumn(overrides?.bitrate)
            },
            {
                header: this.translate.instant("UP_TIME"),
                columnDef: TargetsColumnsDefTypes.UP_TIME,
                width: 120,
                align: "right",
                visible: true,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<AnyTarget>(
                    row => this.uptimePipe.transform(row.target.status?.up_time),
                    row => this.uptimePipe.transform(row.target.status?.up_time),
                    row => !!row.target.status?.up_time
                ),
                textValue: (row: AnyTarget) => {
                    const title = this.uptimePipe.transform(row.target.status?.up_time);
                    return row.target.status && row.target.status?.up_time ? title : "-";
                },
                sortBy: (row: AnyTarget) => row.target.status?.up_time,
                valueToExport: (row: AnyTarget) => row.target.status?.up_time,
                columnFilterType: ColumnFilterType.DURATION,
                columnFilterValue: row => row.target.status?.up_time,
                ...customizeColumn(overrides?.up_time)
            },
            {
                header: this.translate.instant("CHANNEL"),
                columnDef: TargetsColumnsDefTypes.CHANNEL,
                width: 240,
                visible: true,
                component: ZxTargetChannelColumnComponent,
                assignComponentsInputs: assignComponentsTargetChannelsColumnAdapter,
                textValue: row => row.channel_name,
                sortBy: row => row.channel_name,
                valueToExport: row => {
                    let channelText = "";
                    if (row.target.adaptiveChannel)
                        channelText +=
                            row.target.adaptiveChannel?.name +
                            " @ " +
                            row.target.adaptiveChannel?.processingCluster?.name;
                    if (row.target.mediaconnect_flow_id == null && row.delivery && row.target.deliveryChannel)
                        channelText +=
                            row.target.deliveryChannel?.name +
                            " @ " +
                            row.target.deliveryChannel?.processingCluster?.name;
                    if (row.target.mediaconnect_flow_id != null)
                        channelText += row.target.mediaconnectFlow?.name + " @ " + row.target.mediaconnectFlow?.region;
                    if (row.target.medialive_channel_id != null)
                        channelText += row.target.mediaLiveChannel?.name + " @ " + row.target.mediaLiveChannel?.region;
                    return channelText;
                },
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.channel)
            },
            {
                header: this.translate.instant("CHANNEL_TYPE"),
                columnDef: TargetsColumnsDefTypes.CHANNEL_TYPE,
                width: 160,
                visible: true,
                component: ZxChannelTypeComponent,
                assignComponentsInputs: (
                    componentRef: ComponentRef<ZxChannelTypeComponent>,
                    row: KeyMap<AnyTarget>,
                    searchTerm: string[]
                ) => {
                    componentRef.instance.channelType = row.channel_type || "-";
                    componentRef.instance.searchTerm = searchTerm;
                },
                textValue: row => row.channel_type,
                sortBy: row => row.channel_type,
                valueToExport: row => row.channel_type,
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.channel_type)
            },
            {
                header: this.translate.instant("ALT_PATH"),
                columnDef: TargetsColumnsDefTypes.ALTERNATIVE,
                width: 120,
                visible: false,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                    row => (this.targetsService.isAltPath(row.target) ? "Active" : "Inactive"),
                    row => (this.targetsService.isAltPath(row.target) ? "Active" : "Inactive"),
                    () => true
                ),
                sortBy: (row: KeyMap<AnyTarget>) => (this.targetsService.isAltPath(row.target) ? "Active" : "Inactive"),
                textValue: (row: KeyMap<AnyTarget>) =>
                    this.targetsService.isAltPath(row.target) ? "Active" : "Inactive",
                columnFilterType: ColumnFilterType.SELECT,
                columnSelectOptions: ["Active", "Inactive"],
                columnFilterValue: (row: KeyMap<AnyTarget>) =>
                    this.targetsService.isAltPath(row.target) ? "Active" : "Inactive",
                ...customizeColumn(overrides?.alternative)
            },
            {
                header: this.translate.instant("TAGS"),
                columnDef: TargetsColumnsDefTypes.TAGS,
                width: 160,
                visible: false,
                component: ZxTagsListComponent,
                assignComponentsInputs: (
                    targetComponentRef: ComponentRef<ZxTagsListComponent>,
                    row: KeyMap<AnyTarget>,
                    searchTerm: string[]
                ) => {
                    const targetCompRef = targetComponentRef.instance;
                    targetCompRef.model = row.target;
                    targetCompRef.searchTerm = searchTerm.toString();
                },
                textValue: row => row.target.resourceTags?.map(t => t.name).join(", ") ?? "",
                sortBy: row => row.target.resourceTags?.map(t => t.name).join("") ?? "",
                valueToExport: row => row.target.resourceTags?.map(t => t.name).join(", ") ?? "",
                columnFilterType: ColumnFilterType.TAGS,
                columnFilterValue: row => row.target.resourceTags.map(t => t.id),
                ...customizeColumn(overrides?.access_tags)
            },
            {
                header: this.translate.instant("PRIORITY"),
                columnDef: TargetsColumnsDefTypes.PRIORITY,
                width: 80,
                visible: false,
                component: IconColumnComponent,
                assignComponentsInputs: (componentRef: ComponentRef<IconColumnComponent>, row: KeyMap<AnyTarget>) => {
                    const componentInstance = componentRef.instance;
                    componentInstance.iconType = IconTypes.CHECK;
                    componentInstance.showIcon = this.tagsService.isObjectVip(row.target.resourceTags);
                },
                sortBy: (row: AnyTarget) => (this.tagsService.isObjectVip(row.target.resourceTags) ? "priority" : ""),
                textValue: (row: AnyTarget) =>
                    this.tagsService.isObjectVip(row.target.resourceTags) ? "priority" : "",
                columnFilterType: ColumnFilterType.SELECT,
                columnSelectOptions: ["Yes", "No"],
                columnFilterValue: row => (this.tagsService.isObjectVip(row.target.resourceTags) ? "Yes" : "No"),
                ...customizeColumn(overrides?.priority)
            },
            {
                header: this.translate.instant("MUTED"),
                columnDef: TargetsColumnsDefTypes.MUTED,
                width: 0,
                visible: false,
                textValue: row => (row.target.active_mute ? "muted" : ""),
                sortBy: row => (row.target.active_mute ? "muted" : ""),
                columnFilterType: ColumnFilterType.SELECT,
                columnSelectOptions: ["Yes", "No"],
                columnFilterValue: row => (row.target.active_mute ? "Yes" : "No"),
                ...customizeColumn(overrides?.muted)
            },
            {
                header: this.translate.instant("PID_MAPPING"),
                columnDef: TargetsColumnsDefTypes.PID_MAPPING,
                width: 60,
                visible: false,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                    row => (row.target as TargetPidMappingTypes).pid_mapping?.name ?? "",
                    row => (row.target as TargetPidMappingTypes).pid_mapping?.name ?? "",
                    row => !!(row.target as TargetPidMappingTypes).pid_mapping
                ),
                sortBy: (row: KeyMap<AnyTarget>) => (row.target as TargetPidMappingTypes).pid_mapping?.name ?? "",
                textValue: (row: KeyMap<AnyTarget>) => (row.target as TargetPidMappingTypes).pid_mapping?.name ?? "",
                valueToExport: (row: KeyMap<AnyTarget>) =>
                    (row.target as TargetPidMappingTypes).pid_mapping?.name ?? "",
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.pid_mapping)
            },
            {
                header: this.translate.instant("BILLING_CODE"),
                columnDef: TargetsColumnsDefTypes.BILLING_CODE,
                width: 80,
                visible: false,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                    row => (row.target as PublishingTarget)?.billing_code ?? "-",
                    row => (row.target as PublishingTarget)?.billing_code ?? "-",
                    _row => true
                ),
                sortBy: row => (row.target as PublishingTarget)?.billing_code ?? "-",
                textValue: row => (row.target as PublishingTarget)?.billing_code ?? "-",
                valueToExport: row => (row.target as PublishingTarget)?.billing_code ?? "-",
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.billing_code)
            }
        ];
    }
}
