<!-- Basic Search -->
<div class="form-group flex-grow-1 flex-shrink-1 mb-0" *ngIf="showFilter" [style.visibility]="hideSearch ? 'hidden' : 'visible'">
    <mat-form-field
        appearance="outline"
        [ngClass]="{
            hasValue: genericFilter.value.length
        }"
        title="{{ 'BOOLEAN_SEARCH_SUPPORTED' | translate }}"
        [tourAnchor]="showTour ? 'firstTableAnchor' : ''"
    >
        <mat-label
            >{{ "FILTER" | translate }} <span *ngIf="filteredLength !== dataLength">({{ filteredLength }} of {{ dataLength }})</span></mat-label
        >
        <input (keyup)="applyFilters()" matInput placeholder="{{ 'FILTER' | translate }}" [(ngModel)]="genericFilter.value" />
        <button *ngIf="genericFilter.value.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="genericFilter.value = ''; applyFilters()">
            <fa-icon icon="times" size="xs"></fa-icon>
        </button>
    </mat-form-field>
</div>
<!-- Active Column Filters -->
<div *ngIf="filters && filters.length > 1 && showFilter" class="form-group d-flex flex-wrap filter-buttons">
    <ng-container *ngFor="let filter of filters; let i = index">
        <div *ngIf="!FilterGuards.isTableFilter(filter)">
            <button
                zmid="filter-options"
                type="button"
                class="btn btn-outline-secondary active"
                [ngClass]="{ active: filter.rawValue && filter.rawValue.length !== 0 }"
                #filterOptionsMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="filterOptionsMenu"
                (menuClosed)="menuClosed(filter)"
                (menuOpened)="menuOpened(i)"
            >
                <span>{{ filter.columnHeader }}: </span>
                <span *ngIf="operatorTypes[filter.columnType].length > 1 && filter.rawValue">{{ filter.operatorType | translate | lowercase }}</span>
                <span *ngIf="FilterGuards.isDurationFilter(filter) && filter.rawValue"> '{{ filter.rawValue }}' {{ filter.durationType | translate }}</span>
                <span *ngIf="filter.columnType !== 'duration' && filter.columnType !== 'select' && filter.columnType !== 'tags' && filter.rawValue">
                    '{{ filter.rawValue }}'</span
                >
                <span *ngIf="filter.rawValue && filter.columnType === 'select'">
                    <span *ngIf="filter.rawValue.length > 1" class="badge badge-secondary">{{ filter.rawValue.length }}</span>
                    <span *ngIf="filter.rawValue.length === 1">'{{ filter.rawValue }}'</span>
                </span>
                <span *ngIf="filter.rawValue && filter.columnType === 'tags'">
                    <span *ngIf="filter.rawValue.length" class="badge badge-secondary">{{ filter.rawValue.length }}</span>
                </span>
                <!-- Caret -->
                <fa-icon icon="caret-down" size="sm" class="ms-1"></fa-icon>
                <!-- Remove -->
                <fa-icon icon="times" size="md" class="ms-2" (click)="removeFilter(i)" title="{{ 'REMOVE_FILTER' | translate }}"></fa-icon>
            </button>
            <div class="shadow-sm">
                <mat-menu #filterOptionsMenu="matMenu" yPosition="below" class="filter-menu">
                    <ng-container *ngIf="FilterGuards.isSelectFilter(filter)" #focusInput>
                        <ng-container *ngFor="let selectOption of getSelectOptions(filter.columnHeader)">
                            <mat-checkbox
                                class="text-secondary"
                                (click)="$event.stopPropagation()"
                                (change)="addOrRemoveOption(filter.rawValue, selectOption)"
                                [checked]="filter.rawValue.includes(selectOption)"
                                title="{{ selectOption }}"
                            >
                                {{ selectOption }}
                            </mat-checkbox>
                        </ng-container>
                    </ng-container>
                    <!-- Not Select or Date or Tags -->
                    <div
                        class="pt-2 px-3"
                        *ngIf="filter.columnType !== 'select' && filter.columnType !== 'date' && filter.columnType !== 'tags'"
                        (click)="$event.stopPropagation()"
                    >
                        <div class="d-flex flex-row">
                            <!-- Operator -->
                            <div class="d-flex">
                                <span *ngIf="operatorTypes[filter.columnType].length === 1">
                                    {{ operatorTypes[filter.columnType][0] | translate }}
                                </span>
                                <mat-select
                                    *ngIf="operatorTypes[filter.columnType].length > 1"
                                    disableOptionCentering
                                    [(value)]="filter.operatorType"
                                    (selectionChange)="applyFilters()"
                                    title="{{ filter.operatorType | translate }}"
                                    panelClass="{{ filter.columnType === 'string' ? 'minw-100px' : '' }}"
                                    #focusOperator
                                >
                                    <mat-option *ngFor="let filterOperator of operatorTypes[filter.columnType]" [value]="filterOperator">
                                        {{ filterOperator | translate }}
                                    </mat-option>
                                </mat-select>
                                <mat-select
                                    *ngIf="FilterGuards.isDurationFilter(filter)"
                                    disableOptionCentering
                                    [(value)]="filter.durationType"
                                    (selectionChange)="applyFilters()"
                                    title="{{ filter.durationType | translate }}"
                                    panelClass="minw-100px"
                                    #focusDuration
                                >
                                    <mat-option *ngFor="let durationType of durationTypes" [value]="durationType">
                                        {{ durationType | translate }}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <!-- Input -->
                            <div>
                                <input
                                    #focusInput
                                    [type]="filter.columnType === 'string' || filter.columnType === 'string_array' ? 'text' : 'number'"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="filter.rawValue"
                                    (input)="applyFilters()"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- Tags -->
                    <div class="pt-2 px-3" *ngIf="filter.columnType === 'tags'" (click)="$event.stopPropagation()">
                        <div class="d-flex flex-row">
                            <!-- Access Tags -->
                            <zx-access-tags-select-model
                                name="resource_tag_ids"
                                [(model)]="filter.rawValue"
                                (modelChange)="applyFiltersWithTimeout()"
                            ></zx-access-tags-select-model>
                        </div>
                    </div>
                    <div class="dropdown-divider"></div>
                    <!-- Remove -->
                    <div class="px-3 py-2 text-end" (click)="$event.stopPropagation()">
                        <small
                            ><a href="javascript:void(0)" (click)="removeFilter(i)"
                                ><fa-icon icon="times-circle" size="sm" class="me-1"></fa-icon>{{ "REMOVE_FILTER" | translate }}</a
                            ></small
                        >
                    </div>
                </mat-menu>
            </div>
        </div>
    </ng-container>
</div>
<!-- Button to activate filters -->
<div *ngIf="columns.length && !collapsed && showFilter" class="form-group d-flex flex-wrap mb-0">
    <button
        zmid="add-filter"
        type="button"
        class="btn btn-outline-secondary"
        [matMenuTriggerFor]="filtersMenu"
        #addFilterTrigger="matMenuTrigger"
        [tourAnchor]="showTour ? 'secondTableAnchor' : ''"
    >
        <fa-icon icon="plus" size="sm"></fa-icon>
        <span translate class="d-none d-sm-inline-block ms-1">ADD_FILTER</span>
    </button>
    <div class="shadow-sm">
        <mat-menu #filtersMenu="matMenu" yPosition="below" class="{{ columns.length > 14 ? 'twoCol' : '' }}">
            <div class="filter-container">
                <button mat-menu-item *ngFor="let column of columns | orderByLocal : 'header'" (click)="addFilter(column)" title="{{ column.header }}">
                    {{ column.header }}
                </button>
            </div>
            <div class="dropdown-divider"></div>
            <div class="px-3 py-2 text-end">
                <small [tourAnchor]="showTour ? 'thirdTableAnchor' : ''"
                    ><a href="javascript:void(0)" (click)="$event.stopPropagation(); resetFilterConfig()"
                        ><fa-icon icon="undo" class="me-1" size="xs"></fa-icon>{{ "RESET_FILTER_CONFIGURATION" | translate }}</a
                    ></small
                >
            </div>
        </mat-menu>
    </div>
</div>
<!-- Bulk Actions, Column Selection, Report -->
<ng-content></ng-content>
