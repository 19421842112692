import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, Subscriber, lastValueFrom } from "rxjs";
import { share, map } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { StatusTextPipe } from "../../pipes/status-text.pipe";
import { FilterPipe } from "../../pipes/filter.pipe";
import { Constants } from "../../constants/constants";
import {
    Cluster,
    AWSAccount,
    AzureAccount,
    GCPAccount,
    AWSRegionOption,
    AzureRegionOption,
    GCPRegionOption,
    VersionsResponse,
    AWSRegions,
    AzureRegions,
    GCPRegions
} from "./cluster";
import { AuthService } from "src/app/services/auth.service";
import { APIResponse, MediaConnectFlow } from "src/app/models/shared";
import { AdaptiveChannel, DeliveryChannel, FailoverChannel, MediaLiveChannel } from "../channels/channel";
import { SharedService } from "../../services/shared.service";
import { ZenApiService } from "../../services/zen-rpc-service";

import moment from "moment";
import * as _ from "lodash";
import { InferredRequests } from "@zixi/zen-rpc";
@Injectable({
    providedIn: "root"
})
export class ClustersService {
    clusters: Observable<Cluster[]>;
    private clusters$: ReplaySubject<Cluster[]>;
    private dataStore: {
        clusters: Cluster[];
    };

    private lastClustersRefresh: number;
    private zenApi = inject(ZenApiService);

    awsRegions = Constants.awsRegions;
    azureRegions = Constants.azureRegions;
    gcpRegions = Constants.gcpRegions;
    linodeRegions = Constants.linodeRegions;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private translate: TranslateService,
        private stp: StatusTextPipe,
        private fp: FilterPipe,
        private sharedServices: SharedService,
        private zenApiService: ZenApiService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            clusters: []
        };

        this.lastClustersRefresh = null;

        this.clusters$ = new ReplaySubject(1) as ReplaySubject<Cluster[]>;
        this.clusters = this.clusters$.asObservable();
    }

    private prepCluster(cluster: Cluster, update?: boolean) {
        cluster._frontData = {
            sortableStatus: "",
            is_aws: false,
            is_azure: false,
            is_linode: false,
            is_gcp: false,
            prettyGCPZones: "",
            prettyGCPSubnet: "",
            vpcName: "",
            subnetName: "",
            securityGroupName: "",
            scaling: "",
            types: [],
            sortableName: [cluster.name, cluster.id].join(""),
            is_auto_scaling: false,
            prettyRegionName: null,
            lastRefresh: moment().format()
        };

        const existingCluster = _.find(this.dataStore.clusters, c => {
            return c.id === cluster.id;
        });

        // fix empty broadcaster status on updateCluster
        if (existingCluster && update) {
            if (cluster.broadcasters && cluster.broadcasters.length) {
                for (const b of cluster.broadcasters) {
                    if (!b.status) {
                        const existingBroadcaster = existingCluster.broadcasters.find(broad => broad.id === b.id);
                        if (existingBroadcaster) b.status = existingBroadcaster.status;
                    }
                }
            }
        }

        // is?
        cluster._frontData.is_aws = cluster.aws_account_id && cluster.aws_account_id != null ? true : false;
        cluster._frontData.is_azure = cluster.azure_account_id && cluster.azure_account_id != null ? true : false;
        cluster._frontData.is_gcp = cluster.gcp_account_id && cluster.gcp_account_id != null ? true : false;
        cluster._frontData.is_linode = cluster.linode_account_id && cluster.linode_account_id != null ? true : false;
        cluster._frontData.is_auto_scaling =
            cluster._frontData.is_aws ||
            cluster._frontData.is_azure ||
            cluster._frontData.is_linode ||
            cluster._frontData.is_gcp;

        // Scaling
        if (cluster._frontData.is_aws) cluster._frontData.scaling = "AWS";
        if (cluster._frontData.is_azure) cluster._frontData.scaling = "AZURE";
        if (cluster._frontData.is_linode) cluster._frontData.scaling = "LINODE";
        if (cluster._frontData.is_gcp) cluster._frontData.scaling = "GCP";
        if (!cluster._frontData.is_auto_scaling) cluster._frontData.scaling = "MANUAL";

        // Pretty GCP
        if (cluster._frontData.is_gcp) {
            cluster._frontData.prettyGCPZones = _.map(cluster.zones.split(","), zone => {
                return zone.substr(zone.indexOf(cluster.region));
            }).join(", ");

            cluster._frontData.prettyGCPSubnet = cluster.subnet.substr(
                cluster.subnet.indexOf(cluster.region) + (cluster.region + "/subnetworks/").length
            );
        }

        // Pretty Region Name
        if (cluster._frontData.is_aws) {
            cluster._frontData.prettyRegionName = this.fp.transform(this.awsRegions, "value", cluster.region)[0]?.name;
        }
        if (cluster._frontData.is_azure) {
            cluster._frontData.prettyRegionName = this.fp.transform(
                this.azureRegions,
                "value",
                cluster.region
            )[0]?.name;
        }
        if (cluster._frontData.is_gcp) {
            cluster._frontData.prettyRegionName = this.fp.transform(this.gcpRegions, "value", cluster.region)[0]?.name;
        }
        if (cluster._frontData.is_linode) {
            cluster._frontData.prettyRegionName = this.fp.transform(
                this.linodeRegions,
                "value",
                cluster.region
            )[0]?.name;
        }

        // VPC Name, Subnet Name, and Security Group Name
        if (cluster._frontData.is_azure) {
            cluster._frontData.vpcName = _.last(cluster.vpc?.split("/"));
            cluster._frontData.subnetName = _.last(cluster.subnet?.split("/"));
            cluster._frontData.securityGroupName =
                typeof cluster.security_group === "string"
                    ? _.last(cluster.security_group?.split("/"))
                    : "Using Subnet Security Group";
        } else {
            cluster._frontData.vpcName = cluster.vpc;
            cluster._frontData.subnetName = cluster.subnet;
            cluster._frontData.securityGroupName = cluster.security_group;
        }

        this.sharedServices.prepStatusSortFields(cluster);
        if (cluster.resourceTags)
            cluster.resourceTags.sort((a, b) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );

        return cluster;
    }

    private updateStore(cluster: Cluster, merge: boolean, update?: boolean): void {
        let preppedCluster: Cluster;
        if (update) preppedCluster = this.prepCluster(cluster, true);
        else preppedCluster = this.prepCluster(cluster);

        const currentIndex = this.dataStore.clusters.findIndex(u => u.id === preppedCluster.id);
        if (currentIndex === -1) {
            this.dataStore.clusters.push(preppedCluster);
            return;
        }

        if (merge) {
            const current = this.dataStore.clusters[currentIndex];
            Object.assign(current, preppedCluster);
        } else {
            this.dataStore.clusters[currentIndex] = preppedCluster;
        }
    }

    refreshClusters(force?: boolean): Observable<Cluster[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastClustersRefresh <= 60000) return this.clusters;
        this.lastClustersRefresh = _.now();

        const clusters$ = this.http
            .get<APIResponse<Cluster[]>>(Constants.apiUrl + Constants.apiUrls.cluster)
            .pipe(share());

        clusters$.subscribe(
            data => {
                const clusters: Cluster[] = data.result;

                this.dataStore.clusters.forEach((existing, existingIndex) => {
                    const newIndex = clusters.findIndex(b => b.id === existing.id);
                    if (newIndex === -1) this.dataStore.clusters.splice(existingIndex, 1);
                });

                clusters.forEach(refreshedCluster => this.updateStore(refreshedCluster, true));

                this.clusters$.next(Object.assign({}, this.dataStore).clusters);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CLUSTERS"), error)
        );
        return clusters$.pipe(map(r => r.result));
    }

    refreshCluster(val: string | number, force?: boolean): Observable<Cluster> {
        if (!force && this.dataStore.clusters && this.dataStore.clusters.length) {
            let cluster: Cluster;
            if (typeof val === "number") cluster = this.dataStore.clusters.find(c => c.id === val);
            else cluster = this.dataStore.clusters.find(c => c.name === val);
            //
            if (cluster && cluster.hasFullDetails) {
                // Check if last refresh is within last minute
                if (moment().isBefore(moment(cluster._frontData.lastRefresh).add(1, "minutes"))) {
                    return new Observable((observe: Subscriber<Cluster>) => {
                        observe.next(cluster);
                        observe.complete();
                    });
                }
            }
        }

        const id: number =
            typeof val === "number" ? val : this.dataStore.clusters.find(cluster => cluster.dns_prefix === val).id;

        const cluster$ = this.http
            .get<APIResponse<Cluster>>(Constants.apiUrl + Constants.apiUrls.cluster + "/" + id)
            .pipe(share());

        cluster$.subscribe(
            data => {
                const cluster: Cluster = data.result;
                cluster.hasFullDetails = true;
                this.updateStore(cluster, false);
                this.clusters$.next(Object.assign({}, this.dataStore).clusters);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CLUSTER"), error)
        );
        return cluster$.pipe(map(r => r.result));
    }

    getCachedCluster(dnsPrefix?: string, id?: number) {
        if (this.dataStore.clusters && dnsPrefix)
            return this.dataStore.clusters.find(cluster => cluster.dns_prefix === dnsPrefix);
        if (this.dataStore.clusters && id) return this.dataStore.clusters.find(cluster => cluster.id === id);
        return undefined;
    }

    async addCluster(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<APIResponse<Cluster>>(Constants.apiUrl + Constants.apiUrls.cluster, model)
                .toPromise();
            const cluster = result.result;

            this.updateStore(cluster, false);

            this.clusters$.next(Object.assign({}, this.dataStore).clusters);

            return cluster;
        } catch (error) {
            return false;
        }
    }

    async deleteCluster(cluster: Cluster) {
        try {
            const result = await this.http
                .delete<APIResponse<number>>(Constants.apiUrl + Constants.apiUrls.cluster + "/" + `${cluster.id}`)
                .toPromise();

            const deletedId = result.result;
            const clusterIndex = this.dataStore.clusters.findIndex(c => c.id === deletedId);
            if (clusterIndex !== -1) this.dataStore.clusters.splice(clusterIndex, 1);

            this.clusters$.next(Object.assign({}, this.dataStore).clusters);
            return true;
        } catch (error) {
            return false;
        }
    }

    async updateCluster(cluster: Cluster, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<APIResponse<Cluster>>(Constants.apiUrl + Constants.apiUrls.cluster + "/" + `${cluster.id}`, model)
                .toPromise();
            const updatedCluster: Cluster = result.result;

            this.updateStore(updatedCluster, true, true);

            this.clusters$.next(Object.assign({}, this.dataStore).clusters);
            return updatedCluster;
        } catch (error) {
            if (error.status === 428) return true;
            else return false;
        }
    }

    async getClusterChannels(id: number) {
        try {
            const result = await this.http
                .get<
                    APIResponse<
                        (AdaptiveChannel | DeliveryChannel | MediaConnectFlow | MediaLiveChannel | FailoverChannel)[]
                    >
                >(Constants.apiUrl + Constants.apiUrls.cluster + "/" + id + "/channels")
                .toPromise();
            const channels: (
                | AdaptiveChannel
                | DeliveryChannel
                | MediaConnectFlow
                | MediaLiveChannel
                | FailoverChannel
            )[] = result.result;
            return channels;
        } catch (error) {
            return false;
        }
    }

    getBxVersions() {
        return this.zenApi.client.versions.list().then(result => result.body.result);
    }

    async getAWSAccounts() {
        try {
            const result = await this.http
                .get<APIResponse<AWSAccount[]>>(Constants.apiUrl + Constants.apiUrls.aws_accounts)
                .toPromise();
            return result.result;
        } catch (error) {
            return false;
        }
    }

    async getAzureAccounts() {
        try {
            const result = await this.http
                .get<APIResponse<AzureAccount[]>>(Constants.apiUrl + Constants.apiUrls.azure_accounts)
                .toPromise();
            return result.result;
        } catch (error) {
            return false;
        }
    }

    async getGCPAccounts() {
        try {
            const result = await this.http
                .get<APIResponse<GCPAccount[]>>(Constants.apiUrl + Constants.apiUrls.gcp_accounts)
                .toPromise();
            return result.result;
        } catch (error) {
            return false;
        }
    }

    async getAWSRegions(id: number) {
        return lastValueFrom(
            this.http
                .get<APIResponse<AWSRegions>>(
                    Constants.apiUrl + Constants.apiUrls.aws_accounts + "/" + id + Constants.apiUrls.regions
                )
                .pipe(map(result => result.result))
        );
    }

    async getAzureRegions(id: number) {
        return lastValueFrom(
            this.http
                .get<APIResponse<AzureRegions>>(
                    Constants.apiUrl + Constants.apiUrls.azure_accounts + "/" + id + Constants.apiUrls.regions
                )
                .pipe(map(result => result.result))
        );
    }

    async getGCPRegions(id: number) {
        return lastValueFrom(
            this.http
                .get<APIResponse<GCPRegions>>(
                    Constants.apiUrl + Constants.apiUrls.gcp_accounts + "/" + id + Constants.apiUrls.regions
                )
                .pipe(map(result => result.result))
        );
    }

    async getAWSAutoScalingRegionOptions(accountId: number, regionId: string) {
        return lastValueFrom(
            this.http
                .get<APIResponse<AWSRegionOption>>(
                    Constants.apiUrl +
                        Constants.apiUrls.aws_accounts +
                        "/" +
                        accountId +
                        Constants.apiUrls.regions +
                        "/" +
                        regionId +
                        "/options"
                )
                .pipe(map(result => result.result))
        );
    }

    async getAzureAutoScalingRegionOptions(accountId: number, regionId: string) {
        return lastValueFrom(
            this.http
                .get<APIResponse<AzureRegionOption>>(
                    Constants.apiUrl +
                        Constants.apiUrls.azure_accounts +
                        "/" +
                        accountId +
                        Constants.apiUrls.regions +
                        "/" +
                        regionId +
                        "/options"
                )
                .pipe(map(result => result.result))
        );
    }

    async getGCPAutoScalingRegionOptions(accountId: number, regionId: string) {
        return lastValueFrom(
            this.http
                .get<APIResponse<GCPRegionOption>>(
                    Constants.apiUrl +
                        Constants.apiUrls.gcp_accounts +
                        "/" +
                        accountId +
                        Constants.apiUrls.regions +
                        "/" +
                        regionId
                )
                .pipe(map(result => result.result))
        );
    }

    async createRedirectionRouting(
        clusterId: number,
        requestBody: InferredRequests["redirectionRouting"]["create"]["body"]
    ) {
        try {
            await this.zenApiService.client.redirectionRouting.create({
                params: { broadcaster_cluster_id: clusterId },
                body: requestBody
            });
        } catch (error) {
            throw new Error(error);
        }
    }

    async updateRedirectionRouting(
        redirectionRoutingId: number,
        clusterId: number,
        requestBody: InferredRequests["redirectionRouting"]["update"]["body"]
    ) {
        try {
            await this.zenApiService.client.redirectionRouting.update({
                params: { broadcaster_cluster_id: clusterId, id: redirectionRoutingId },
                body: requestBody
            });
        } catch (error) {
            throw new Error(error);
        }
    }

    async deleteRedirectionRouting(clusterId: number, RedirectionRoutingId: number) {
        try {
            const result = await this.zenApiService.client.redirectionRouting.destroy({
                params: { broadcaster_cluster_id: clusterId, id: RedirectionRoutingId }
            });
            return Boolean(result.body.success);
        } catch (error) {
            return false;
        }
    }
}
