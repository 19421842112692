<div class="form-group">
    <label [ngClass]="{ 'is-invalid': invalid }">
        {{ title }}<fa-icon *ngIf="required" icon="asterisk" size="xs"></fa-icon>
        <fa-icon *ngIf="circleContent" icon="info-circle" [ngbTooltip]="selectContent" triggers="hover click" [closeDelay]="500"></fa-icon>
        <ng-template #selectContent>{{ circleContent }}</ng-template>
        <fa-icon *ngIf="isRestartRequiredTip" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"> </fa-icon>
        <ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template></label
    >
    <ng-select
        [id]="elementId"
        [items]="items"
        [clearable]="clearable"
        [multiple]="multiple"
        [placeholder]="placeHolder"
        bindLabel="name"
        [ngClass]="{ 'form-control is-invalid': invalid }"
        [bindValue]="valueToBind"
        (change)="onChangeValue($event)"
        [loading]="loading"
        (focus)="markAsTouched()"
        [formControl]="formControl"
    >
    </ng-select>

    <app-input-errors-indicator [errors]="ngControl.errors" [fieldName]="title" [invalid]="invalid"> </app-input-errors-indicator>

    <small *ngIf="note">{{ note | translate }}</small>
</div>
