import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { ChannelsService } from "../../../pages/channels/channels.service";
import { MediaLiveChannel } from "../../../pages/channels/channel";

@Component({
    selector: "zx-medialive-channel-select",
    templateUrl: "./zx-medialive-channel-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxMediaLiveChannelSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clearable?: boolean;
    @Input() filter?: (MediaLiveChannel) => boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    mediaLiveChannels: MediaLiveChannel[];
    private sub: Subscription;

    constructor(private cs: ChannelsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.cs.getMediaLiveChannels();
        this.sub = this.cs.medialiveChannels.subscribe(channels => {
            this.mediaLiveChannels = [...channels];
            if (this.filter) this.mediaLiveChannels = this.mediaLiveChannels.filter(this.filter);
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
