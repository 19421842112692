import { Optional, Self } from "@angular/core";
import { ControlValueAccessor, NgControl, Validators, FormControl } from "@angular/forms";
import { isBoolean } from "lodash";

export abstract class CustomControlValueAccessor<T> implements ControlValueAccessor {
    abstract isParentFormSubmitted: boolean;
    disabled?: boolean;
    controlledValue: T = undefined;
    touched = false;

    get invalid() {
        return this.ngControl.status === "INVALID" && (this.dirty || this.isParentFormSubmitted);
    }

    get required() {
        return this.ngControl.control.hasValidator(Validators.required);
    }

    get dirty() {
        return this.ngControl?.dirty;
    }

    get formControl() {
        return this.ngControl.control as FormControl;
    }

    constructor(@Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        if (isBoolean(this.disabled)) {
            this.setDisabledState(this.disabled);
        }
    }

    markAsTouched() {
        if (this.touched) {
            return;
        }
        this._onTouched();
        this.touched = true;
    }

    _onChange: (item: T) => void;

    _onTouched: () => void;

    // Accepts value from the form control
    writeValue(newValue: T) {
        this.controlledValue = newValue;
    }

    // Registers a function to update the form control
    registerOnChange(onChange) {
        this._onChange = onChange;
    }

    // Registers a function to update the form control's touched state
    registerOnTouched(onTouched) {
        this._onTouched = onTouched;
    }

    // Accepts disabled state from the form control
    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
}
