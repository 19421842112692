<div
    *ngIf="detailsList && detailsList?.length"
    [ngClass]="{ 'primary-data-section': isPrimaryDetails, 'd-none d-md-flex secondary-data-section': !isPrimaryDetails }"
    [tourAnchor]="isPrimaryDetails ? 'thirdCustomLayoutAnchor' : 'fourthCustomLayoutAnchor'"
>
    <div class="d-flex flex-grow-0 flex-shrink-1 flex-wrap" cdkDropListGroup *ngIf="!isSectionHidden">
        <ng-container *ngFor="let details of nonHiddenDetails; let i = index">
            <ul
                class="flex-list"
                [ngClass]="{ 'primary-data': isPrimaryDetails }"
                cdkDropList
                (cdkDropListDropped)="drop($event)"
                cdkDropListOrientation="horizontal"
                [cdkDropListData]="{ item: details, index: i }"
                [cdkDropListDisabled]="isLocked"
            >
                <li class="flex-li" cdkDrag [ngClass]="{ 'd-none': details.isDeleted }">
                    <div
                        class="flex-item"
                        [ngClass]="{ 'primary-data': isPrimaryDetails, disable: isLocked, isThumbnail: details.title === ('THUMBNAIL' | translate) }"
                    >
                        <div class="d-flex">
                            <div *ngFor="let detail of details.data">
                                <div class="form-group mb-0" *ngIf="detail.content">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-xs remove-icon"
                                        [disabled]="isLocked"
                                        [attr.title]="'REMOVE_TILE' | translate"
                                        (click)="addOrRemoveDetail(detail, true)"
                                    >
                                        <fa-icon *ngIf="!isPrimaryDetails" icon="square-minus" size="md"></fa-icon>
                                    </button>
                                    <app-new-details [details]="detail" [isBoldContent]="isPrimaryDetails" (refreshPage)="refresh()"></app-new-details>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </ng-container>
        <div
            *ngIf="!isPrimaryDetails && !isSectionHidden"
            ngbDropdown
            class="d-flex align-items-center"
            [ngStyle]="{ 'min-height': '30px' }"
            id="addDetailsDropdown"
            tourAnchor="fifthCustomLayoutAnchor"
        >
            <button
                type="button"
                class="btn btn-sm btn-xs add-button"
                [matMenuTriggerFor]="detailsMenu"
                #detailsMenuTrigger="matMenuTrigger"
                container="body"
                [disabled]="isLocked"
                [attr.title]="'TILE_SELECTION' | translate"
                triggers="hover"
            >
                <fa-icon [fixedWidth]="true" icon="square-pen" size="lg"></fa-icon>
            </button>
            <div aria-labelledby="addDetailsDropdown" class="shadow-sm">
                <mat-menu #detailsMenu="matMenu" yPosition="below" class="{{ nonHiddenDetails.length > 14 ? 'twoCol' : '' }}">
                    <ng-container *ngFor="let detail of nonHiddenDetailsCopy | orderBy : 'title'">
                        <mat-checkbox
                            class="text-secondary"
                            (click)="$event.stopPropagation()"
                            (change)="addOrRemoveDetail(detail, !detail.isDeleted)"
                            [checked]="!detail.isDeleted"
                            title="{{ detail.title }}"
                        >
                            {{ detail.title }}
                        </mat-checkbox>
                    </ng-container>
                </mat-menu>
            </div>
        </div>
    </div>
    <button
        *ngIf="!isPrimaryDetails"
        type="button"
        class="btn btn-sm btn-xs hide-button"
        [ngClass]="{ 'show-button': isSectionHidden, visible: showHideSectionButton }"
        container="body"
        [attr.title]="(isSectionHidden ? 'EXPAND_SECONDARY_INFO' : 'COLLAPSE') | translate"
        triggers="hover"
        placement="left"
        (click)="toggleSecondaryDetails()"
        [disabled]="isLocked"
        tourAnchor="hideSecondaryDetailsSectionCustomLayoutAnchor"
    >
        <fa-icon icon="angle-double-down" *ngIf="isSectionHidden"></fa-icon>
        <fa-icon icon="angle-double-up" *ngIf="!isSectionHidden"></fa-icon>
    </button>
    <app-pin-button
        *ngIf="!isSectionHidden"
        class="d-none d-md-block pin-button"
        [ngClass]="{ 'show-pin-button': showPinButton, 'ms-0': !isSectionHidden && !isPrimaryDetails }"
        [isPinned]="isSectionPinned"
        (isPinnedChange)="isSectionPinnedChange.emit($event)"
        [isLocked]="isLocked"
        [tourAnchor]="!isPrimaryDetails ? 'sixthCustomLayoutAnchor' : ''"
    ></app-pin-button>
</div>
