import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, Subscriber, from } from "rxjs";
import { map, share } from "rxjs/operators";
import * as _ from "lodash";
//
import { Constants } from "../../constants/constants";
import { NominatimResponse, Map, Nominatim, LayerGroupData } from "./map";
//
import { AuthService } from "src/app/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ZenApiService } from "src/app/services/zen-rpc-service";

interface MapPayload {
    type: string;
    region: string;
    baseMap: string;
    start_clustered: number | boolean;
    name: any;
    resource_tag_ids: any[];
    configVersion: number;
    config: {
        mediaconnect_flows: number[];
        adaptive_channels: number[];
        delivery_channels: number[];
        groups: {
            id: number;
            name: string;
            feeders?: number[];
            receivers?: number[];
            zecs?: number[];
            sources?: number[];
            mediaconnect_sources?: number[];
            broadcasters?: number[];
            targets?: string[];
        }[];
        leafletData: LayerGroupData[];
    };
}

@Injectable({
    providedIn: "root"
})
export class MapService {
    constants = Constants;
    maps: Observable<Map[]>;
    private maps$: ReplaySubject<Map[]>;
    private dataStore: {
        maps: Map[];
    };

    private lastMapsRefresh: number;
    private lastMapRefresh: number;
    private zenApi = inject(ZenApiService);

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    // API - https://nominatim.org/release-docs/develop/
    addressLookup(query?: string, bounded?: boolean): Observable<NominatimResponse[]> {
        const url = `https://${this.constants.BASE_NOMINATIM_URL}/search?addressdetails=1&format=json&q=${query}&${
            this.constants.DEFAULT_VIEW_BOX
        }&bounded=${bounded ? 1 : 0}`;
        const result = this.http
            .get(url)
            .pipe(
                map((data: Nominatim[]) =>
                    data.map(
                        (item: Nominatim) => new NominatimResponse(item.lat, item.lon, item.display_name, item.address)
                    )
                )
            );
        return result;
    }

    latLngLookup(lat: number, lng: number): Observable<NominatimResponse> {
        const url = `https://nominatim.openstreetmap.org/reverse?addressdetails=1&format=json&lat=${lat}&lon=${lng}`;
        const result = this.http
            .get(url)
            .pipe(map((data: Nominatim) => new NominatimResponse(data.lat, data.lon, data.display_name, data.address)));
        return result;
    }

    private reset() {
        this.dataStore = {
            maps: []
        };

        this.maps$ = new ReplaySubject(1) as ReplaySubject<Map[]>;
        this.maps = this.maps$.asObservable();
    }

    private prepMap(m: Map) {
        m._frontData = {
            numberOfLayers: null
        };

        // Find count of number of layer groups
        if (m.config) {
            if (m.config.mediaconnect_flows && m.config.mediaconnect_flows.length > 0)
                m._frontData.numberOfLayers += m.config.mediaconnect_flows.length;
            if (m.config.adaptive_channels && m.config.adaptive_channels.length > 0)
                m._frontData.numberOfLayers += m.config.adaptive_channels.length;
            if (m.config.delivery_channels && m.config.delivery_channels.length > 0)
                m._frontData.numberOfLayers += m.config.delivery_channels.length;
            if (m.config.groups && m.config.groups.length > 0) m._frontData.numberOfLayers += m.config.groups.length;
        }
    }

    private updateMapStore(newMap: Map, prep?: boolean): void {
        if (prep) this.prepMap(newMap);
        const currentIndex = this.dataStore.maps.findIndex(m => m.id === newMap.id);
        if (currentIndex === -1) {
            this.dataStore.maps.push(newMap);
        } else {
            this.dataStore.maps[currentIndex] = newMap;
        }
    }

    refreshMaps(force?: boolean): Observable<Map[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastMapsRefresh <= 60000) return this.maps;
        this.lastMapsRefresh = _.now();

        const maps$ = from(this.zenApi.client.maps.list()).pipe(share());

        maps$.subscribe(
            data => {
                const maps = data.body.result as unknown as Map[];

                this.dataStore.maps.forEach((existing, existingIndex) => {
                    const newIndex = maps.findIndex(m => m.id === existing.id);
                    if (newIndex === -1) this.dataStore.maps.splice(existingIndex, 1);
                });

                maps.forEach(m => this.updateMapStore(m, true));

                this.maps$.next(Object.assign({}, this.dataStore).maps);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MAPS"), error)
        );
        return maps$.pipe(map(m => m.body.result as unknown as Map[]));
    }

    refreshMap(id: number, force?: boolean): Observable<Map> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastMapRefresh <= 60000) {
            return new Observable((observe: Subscriber<Map>) => {
                observe.next(this.dataStore.maps.find(m => m.id === id));
                observe.complete();
            });
        }
        this.lastMapRefresh = _.now();

        const map$ = from(this.zenApi.client.maps.get({ params: { id } })).pipe(share());

        map$.subscribe(
            data => {
                const map = data.body.result as unknown as Map;

                this.updateMapStore(map, true);

                this.maps$.next(Object.assign({}, this.dataStore).maps);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MAP"), error)
        );
        return map$.pipe(map(m => m.body.result as unknown as Map));
    }

    getCachedMap(id: number) {
        if (this.dataStore.maps) return this.dataStore.maps.find(m => m.id === id);
        return undefined;
    }

    async deleteMap(m: Map): Promise<boolean> {
        try {
            const result = await this.zenApi.client.maps.destroy({ params: { id: m.id } });

            const deletedId = result.body.result;

            const index = this.dataStore.maps.findIndex(f => f.id === deletedId);
            if (index !== -1) this.dataStore.maps.splice(index, 1);

            this.maps$.next(Object.assign({}, this.dataStore).maps);
            return true;
        } catch (error) {
            return false;
        }
    }

    async addMap(body: MapPayload): Promise<Map | false> {
        try {
            const result = await this.zenApi.client.maps.create({ body });

            const map = result.body.result as unknown as Map;

            this.updateMapStore(map, true);

            this.maps$.next(Object.assign({}, this.dataStore).maps);
            return map;
        } catch (error) {
            return false;
        }
    }

    async updateMap(
        id: number,
        body: Omit<MapPayload, "start_clustered" | "resource_tag_ids" | "configVersion">
    ): Promise<Map | false> {
        try {
            const result = await this.zenApi.client.maps.update({ params: { id }, body });
            const updatedMap = result.body.result as unknown as Map;

            this.updateMapStore(updatedMap, true);

            this.maps$.next(Object.assign({}, this.dataStore).maps);
            return updatedMap;
        } catch (error) {
            return false;
        }
    }
}
