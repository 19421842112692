<ngb-alert
    *ngFor="let activeState of activeStates"
    [dismissible]="false"
    [type]="activeState.type === 'error' ? 'danger' : 'warning'"
    [ngClass]="{ disabled: activeState.acknowledged }"
>
    <div class="d-flex flex-column align-items-stretch flex-md-row">
        <div class="flex-fill">
            <strong>{{ (activeState.type === "error" ? "ERROR" : "WARNING") | translate }}</strong> - {{ activeState.message }}
        </div>
        <div class="alert-buttons ms-0 ms-md-3">
            <span class="me-2" *ngIf="showStatus">
                <strong translate>STATUS</strong>:
                <span *ngIf="activeState.deescalate_at"> {{ "CLEARING_AT" | translate }} {{ getDate(activeState.deescalate_at) }} </span>
                <span *ngIf="!activeState.deescalate_at" translate>ACTIVE</span>
            </span>
            <button
                *ngIf="activeState.stateful && canEdit"
                class="btn btn-sm btn-round-sm me-1"
                (click)="acknowledgeAlert(activeState)"
                [title]="activeState.acknowledged ? ('CLEAR_ACKNOWLEDGE' | translate) : ('ACKNOWLEDGE' | translate)"
            >
                <fa-icon icon="check" size="sm" [fixedWidth]="true" *ngIf="!activeState.acknowledged"></fa-icon>
                <fa-icon icon="times" size="sm" [fixedWidth]="true" *ngIf="activeState.acknowledged"></fa-icon>
                <span *ngIf="activeState.acknowledging" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
            </button>
            <button *ngIf="canEdit" class="btn btn-sm btn-round-sm me-1" (click)="clearAlert(activeState)" title="{{ 'CLEAR' | translate }}">
                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                <span *ngIf="activeState.clearing" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
            </button>
            <button *ngIf="canEdit" class="btn btn-sm btn-round-sm" (click)="configureAlert(activeState)" title="{{ 'CONFIGURE' | translate }}">
                <fa-icon icon="cog" size="sm" [fixedWidth]="true"></fa-icon>
            </button>
        </div>
    </div>
</ngb-alert>
