import { Component, Input } from "@angular/core";
import { ActiveState } from "../../models/shared";
import { ActiveStatesService } from "../active-states/active-states.service";
import { MatDialog } from "@angular/material/dialog";
import {
    EditRuleDialogComponent,
    EditRuleDialogData
} from "src/app/pages/configuration/events-management/edit-rule-dialog/edit-rule-dialog.component";
import { DatePipe } from "@angular/common";

@Component({
    selector: "zx-active-states",
    templateUrl: "./active-states.component.html",
    providers: [DatePipe]
})
export class ActiveStatesComponent {
    @Input() activeStates: ActiveState[];
    @Input() refreshFunction?: () => void;
    @Input() canEdit: boolean;
    @Input() showStatus = true;
    @Input() objectName?: string;

    dateFormat = "h:mm a";

    constructor(
        private activeStatesService: ActiveStatesService,
        private dialog: MatDialog,
        private datePipe: DatePipe
    ) {}

    async acknowledgeAlert(activeState: ActiveState) {
        activeState.acknowledging = true;
        const model = { id: activeState.id, acknowledged: null };
        if (activeState.acknowledged) model.acknowledged = 0;
        else model.acknowledged = 1;
        //
        const result = await this.activeStatesService.updateActiveState(model);
        if (result) {
            this.refreshFunction();
        }
        activeState.acknowledging = false;
    }

    async configureAlert(activeState: ActiveState) {
        const dialogEvent: EditRuleDialogData = {
            created_at: activeState.created_at,
            error_code: activeState.code,
            error_group: activeState.group,
            id: activeState.id.toString(),
            message: activeState.message,
            object_id: activeState.object_id,
            object_type: activeState.object_type,
            short_message: activeState.short_message,
            updated_at: activeState.updated_at,
            object_name: this.objectName
        };
        this.dialog.open<EditRuleDialogComponent, EditRuleDialogData>(EditRuleDialogComponent, {
            data: dialogEvent,
            width: "600px"
        });
    }

    async clearAlert(activeState: ActiveState) {
        activeState.clearing = true;
        const result = await this.activeStatesService.deleteActiveState(activeState.id);
        if (result) {
            this.refreshFunction();
        }
        activeState.clearing = false;
    }

    getDate(time: string): string {
        const date = new Date(time);
        const dateISOString = date.toISOString();
        return this.datePipe.transform(dateISOString, this.dateFormat);
    }
}
