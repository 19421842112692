import { Component, Input } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Base } from "@zixi/models";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

export interface ActionConfigs<T = Base> {
    icon: IconProp;
    translateTitle: string;
    onClickHandler: (row: T) => void;
    url?: string;
    trustedUrl?: SafeUrl;
    cssClasses?: string;
    target?: string;
    isVisible?: boolean;
    isVisibleCallback?: (row: T) => boolean;
    isTextVisible?: boolean;
    isIconVisible?: boolean;
    iconSize?: SizeProp;
}
const defaultConfigs: Pick<
    ActionConfigs,
    "url" | "cssClasses" | "target" | "isVisible" | "isTextVisible" | "isIconVisible" | "iconSize"
> = {
    url: "javascript:void(0)",
    cssClasses: "",
    target: "_blank",
    isVisible: true,
    isTextVisible: false,
    isIconVisible: true,
    iconSize: "sm"
};

@Component({
    selector: "app-zx-table-actions",
    templateUrl: "./zx-table-actions.component.html"
})
export class ZxTableActionsComponent {
    private _actionsConfigs;
    @Input() row: Base;

    constructor(private sanitizer: DomSanitizer) {}

    @Input() set actionsConfigs(value: ActionConfigs[]) {
        this._actionsConfigs = value.map(actionConfig => {
            return {
                ...defaultConfigs,
                ...actionConfig,
                trustedUrl: this.sanitizer.bypassSecurityTrustUrl(actionConfig.url ?? defaultConfigs.url)
            };
        });
    }

    get actionsConfigs(): ActionConfigs[] {
        return this._actionsConfigs;
    }

    onClickHandler(event: MouseEvent, actionConfigs: ActionConfigs) {
        event.stopPropagation();
        actionConfigs.onClickHandler(this.row);
    }
}
