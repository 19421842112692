import { Component, Input } from "@angular/core";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

@Component({
    selector: "zx-icon",
    template: `<fa-icon [icon]="icon" *ngIf="showIcon" [size]="iconSize"></fa-icon>`
})
export class ZxIconComponent {
    @Input() icon: IconProp;
    @Input() showIcon = true;
    @Input() iconSize: SizeProp = "1x";
}
