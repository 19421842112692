import { ComponentRef, Injectable, inject } from "@angular/core";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { KeyMap, RecoveryState } from "src/app/models/shared";
import {
    ChannelTypes,
    FailoverChannel,
    isAdaptiveChannel,
    isDeliveryChannel,
    isMediaConnect,
    isMediaLive
} from "./channel";
import { TranslateService } from "@ngx-translate/core";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { ZxClusterComponent } from "src/app/components/shared/zx-cluster/zx-cluster.component";
import { ZxBroadcasterComponent } from "src/app/components/shared/zx-broadcaster/zx-broadcaster.component";
import { ZxChannelsSourceComponent } from "src/app/components/shared/table-list/tables-components/zx-channels-source/zx-channels-source.component";
import { ZxChannelTypeComponent } from "src/app/components/shared/zx-channel-type/zx-channel-type.component";
import { ZxChannelsTargetColumnComponent } from "src/app/components/shared/zx-channels-target-column/zx-channels-target-column.component";
import { ZxTagsListComponent } from "src/app/components/shared/zx-tags-list/zx-tags-list.component";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { TagsService } from "../configuration/tags/tags.service";
import { ChannelsService } from "./channels.service";
import * as _ from "lodash";

export enum ChannelsColumnsDefTypes {
    NAME = "name",
    STATUS = "status",
    ERROR = "error",
    EVENT = "message",
    EXTERNAL_ID = "external_id",
    PROC_CLUSTER = "processing_cluster",
    BROADCASTER = "active_broadcaster",
    SOURCES = "sources",
    TYPE = "type",
    TARGETS = "targets_summary",
    ALT_PATH = "alternative",
    TAGS = "access_tags",
    PRIORITY = "priority",
    MUTED = "muted"
}

type ColDefinition = TableSchema<KeyMap<ChannelTypes>>;
type CustomFields = Partial<{ width?: number; visible?: boolean } | undefined>;
type Overrides = Partial<Record<ChannelsColumnsDefTypes, CustomFields>>;
const customizeColumn = (fields: CustomFields): CustomFields => {
    return _.pick(fields, ["width", "visible"]) as CustomFields;
};

@Injectable({
    providedIn: "root"
})
export class ChannelsDefinitions {
    private translate = inject(TranslateService);
    private statusTextPipe = inject(StatusTextPipe);
    private tagsService = inject(TagsService);
    private channelsService = inject(ChannelsService);

    _sortDirection: string;

    set currentTableSortDirection(value: string) {
        this._sortDirection = value;
    }

    get currentTableSortDirection(): string {
        return this._sortDirection;
    }

    getTableColumnsSchema(overrides?: Overrides): ColDefinition[] {
        return [
            {
                header: this.translate.instant("NAME"),
                columnDef: ChannelsColumnsDefTypes.NAME,
                width: 160,
                visible: true,
                sticky: 1,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<ChannelTypes>>(
                    row => row.name,
                    row => row.name,
                    () => true
                ),
                sortBy: row => row.name,
                textValue: row => row.name,
                valueToExport: row => row.name,
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.name)
            },
            {
                header: this.translate.instant("STATUS"),
                columnDef: ChannelsColumnsDefTypes.STATUS,
                width: 160,
                visible: true,
                component: ZxStatusFullComponent,
                assignComponentsInputs: assignComponentsStatusInputsFactory({ showOtherIcons: true }),
                textValue: row => this.translate.instant(this.statusTextPipe.transform(row)),
                sortBy: row =>
                    this.currentTableSortDirection === "asc"
                        ? row._sortData.sortableStatusAsc
                        : row._sortData.sortableStatusDesc,
                valueToExport: row => this.translate.instant(this.statusTextPipe.transform(row)),
                columnFilterType: ColumnFilterType.SELECT,
                columnFilterValue: row => this.translate.instant(this.statusTextPipe.simpleTransform(row)),
                columnSelectOptions: ["Ok", "Warning", "Error", "Other"],
                ...customizeColumn(overrides?.status)
            },
            {
                header: this.translate.instant("ERROR"),
                columnDef: ChannelsColumnsDefTypes.ERROR,
                hideFromColumnChooser: true,
                width: 100,
                visible: false,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory(
                    row => (row.activeStates ? row.activeStates.map(as => as.short_message)?.join(",") : ""),
                    row => (row.activeStates ? row.activeStates.map(as => as.short_message)?.join(",") : ""),
                    row => !!row.activeStates?.map(as => as.short_message)?.join(",")
                ),
                valueToExport: row => (row.activeStates ? row.activeStates.map(as => as.short_message)?.join(",") : ""),
                ...customizeColumn(overrides?.error)
            },
            {
                header: this.translate.instant("EVENT"),
                columnDef: ChannelsColumnsDefTypes.EVENT,
                width: 100,
                visible: false,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory(
                    row => (row.activeStates ? row.activeStates?.map(as => as.message)?.join(",") : ""),
                    row => (row.activeStates ? row.activeStates?.map(as => as.message)?.join(",") : ""),
                    row => !!row.activeStates?.map(as => as.message)?.join(",")
                ),
                valueToExport: row => (row.activeStates ? row.activeStates?.map(as => as.message)?.join(",") : ""),
                textValue: row => (row.activeStates ? row.activeStates?.map(as => as.message)?.join(",") : ""),
                sortBy: row => (row.activeStates ? row.activeStates?.map(as => as.message)?.join(",") : ""),
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.message)
            },
            {
                header: this.translate.instant("EXTERNAL_ID"),
                columnDef: ChannelsColumnsDefTypes.EXTERNAL_ID,
                width: 100,
                visible: false,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<FailoverChannel>(
                    row => row.external_id ?? "",
                    row => row.external_id ?? "",
                    row => !!row.external_id
                ),
                valueToExport: row => (row as FailoverChannel).external_id ?? "",
                textValue: row => (row as FailoverChannel).external_id ?? "",
                sortBy: row => (row as FailoverChannel).external_id ?? "",
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.external_id)
            },
            {
                header: this.translate.instant("PROC_CLUSTER"),
                columnDef: ChannelsColumnsDefTypes.PROC_CLUSTER,
                width: 200,
                visible: true,
                component: ZxClusterComponent,
                assignComponentsInputs: (
                    clusterComponentRef: ComponentRef<ZxClusterComponent>,
                    row: KeyMap<ChannelTypes>,
                    searchTerm: string[]
                ) => {
                    const clusterCompRef = clusterComponentRef.instance;
                    const props = {
                        model: row?._frontData?.active_broadcaster,
                        showOtherIcons: false,
                        showStatusIcon: false,
                        showStatusText: false,
                        showLink: true,
                        showTag: false,
                        searchTerm: searchTerm
                    };
                    for (const key in props) {
                        const value = props[key];
                        clusterCompRef[key] = value;
                    }

                    if (!isMediaConnect(row) && !isMediaLive(row)) {
                        clusterCompRef.model = row.processingCluster;
                    } else {
                        clusterCompRef.region = row.region;
                    }
                },
                textValue: row => {
                    if (!isMediaConnect(row) && !isMediaLive(row)) return row.processingCluster?.name;
                    return row._frontData?.active_broadcaster?.name ?? row.region;
                },
                sortBy: row => {
                    if (!isMediaConnect(row) && !isMediaLive(row)) return row.processingCluster?.name;
                    return row._frontData?.active_broadcaster?.name ?? row.region;
                },
                valueToExport: row => {
                    if ((row.adaptive || row.delivery) && row.processingCluster && row.processingCluster.name != null)
                        return row.processingCluster.name;
                    if ((row.mediaconnect || row.medialive) && row.region) return row.region;
                    return "";
                },
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.processing_cluster)
            },
            {
                header: this.translate.instant("BROADCASTER"),
                columnDef: ChannelsColumnsDefTypes.BROADCASTER,
                width: 160,
                visible: true,
                component: ZxBroadcasterComponent,
                assignComponentsInputs: (
                    broadcasterComponentRef: ComponentRef<ZxBroadcasterComponent>,
                    row: KeyMap<ChannelTypes>,
                    searchTerm: string[]
                ) => {
                    const broadcasterCompRef = broadcasterComponentRef.instance;
                    const props = {
                        model: row?._frontData?.active_broadcaster,
                        clusterId: row?.broadcaster_cluster_id,
                        showTag: false,
                        showLink: true,
                        showCluster: false,
                        showStatusIcon: false,
                        searchTerm: searchTerm
                    };
                    for (const key in props) {
                        const value = props[key];
                        broadcasterCompRef[key] = value;
                    }
                    if (!isMediaConnect(row)) {
                        broadcasterCompRef.clusterId = row.broadcaster_cluster_id;
                    }
                },
                textValue: row => row?._frontData?.active_broadcaster?.name ?? "-",
                sortBy: row => row?._frontData?.active_broadcaster?.name ?? "",
                valueToExport: row =>
                    row._frontData?.active_broadcaster && row._frontData?.active_broadcaster.name
                        ? row._frontData?.active_broadcaster.name
                        : "",
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.active_broadcaster)
            },
            {
                header: this.translate.instant("SOURCES"),
                columnDef: ChannelsColumnsDefTypes.SOURCES,
                width: 240,
                visible: true,
                component: ZxChannelsSourceComponent,
                assignComponentsInputs: (
                    sourceComponentRef: ComponentRef<ZxChannelsSourceComponent>,
                    row: KeyMap<ChannelTypes>,
                    searchTerm: string[]
                ) => {
                    const statusComponentInstance = sourceComponentRef.instance;
                    statusComponentInstance.channel = row;
                    statusComponentInstance.searchTerm = searchTerm?.toString();
                },
                textValue: row => {
                    if (isAdaptiveChannel(row)) {
                        return row.bitrates?.map(b => b.source?.stream_id).join("");
                    } else if (isDeliveryChannel(row)) {
                        return row.sources?.map(s => s.source?.stream_id).join("");
                    } else if (isMediaConnect(row)) {
                        return row.source?.name || "";
                    } else if (isMediaLive(row)) {
                        return row.flow?.name ?? row.source?.name;
                    } else {
                        return row.failoverSource?.name ?? "";
                    }
                },
                sortBy: row => row._frontData?.sortableSources ?? "",
                valueToExport: row => {
                    let sources = "";
                    if (row.delivery && row.sources && row.sources.length) {
                        const sourceDescriptions: string[] = [];
                        for (const src of row.sources) {
                            const srcDesc: string[] = [src.source.name];
                            if (src.source.inputCluster.name) srcDesc.push(`@${src.source.inputCluster.name}`);
                            sourceDescriptions.push(srcDesc.join(""));
                        }
                        sources += sourceDescriptions.join(", ");
                    }
                    if (row.mediaconnect && row.source && row.source.name)
                        sources +=
                            row.source.name +
                            (row.source.mediaconnectFlow && row.source.mediaconnectFlow.name
                                ? " @ " + row.source.mediaconnectFlow.name
                                : "");
                    if (row.medialive && row.source && row.source.name) sources += row.source.name;
                    if (row.medialive && row.flow && row.flow.name) sources += row.flow.name;
                    return sources;
                },
                ...customizeColumn(overrides?.sources)
            },
            {
                header: this.translate.instant("TYPE"),
                columnDef: ChannelsColumnsDefTypes.TYPE,
                width: 160,
                visible: true,
                component: ZxChannelTypeComponent,
                assignComponentsInputs: (
                    ZxChannelTypeRef: ComponentRef<ZxChannelTypeComponent>,
                    row: KeyMap<ChannelTypes>,
                    searchTerm: string[]
                ) => {
                    ZxChannelTypeRef.instance.channelType = this.channelsService.processChannelType(row);
                    ZxChannelTypeRef.instance.searchTerm = searchTerm;
                },
                textValue: row => this.channelsService.processChannelType(row),
                sortBy: row => this.channelsService.processChannelType(row),
                valueToExport: row => this.channelsService.processChannelType(row),
                columnFilterType: ColumnFilterType.STRING,
                ...customizeColumn(overrides?.type)
            },
            {
                header: this.translate.instant("TARGETS"),
                columnDef: ChannelsColumnsDefTypes.TARGETS,
                width: 240,
                visible: true,
                component: ZxChannelsTargetColumnComponent,
                assignComponentsInputs: (
                    targetComponentRef: ComponentRef<ZxChannelsTargetColumnComponent>,
                    row: KeyMap<ChannelTypes>
                ) => {
                    const targetCompRef = targetComponentRef.instance;
                    const props = {
                        channel: row
                    };
                    for (const key in props) {
                        const value = props[key];
                        targetCompRef[key] = value;
                    }
                },
                textValue: row => {
                    if (row.targetsSummary?.good > 0) {
                        return row.targetsSummary.good.toString();
                    } else if (row.targetsSummary?.bad > 0) {
                        return row.targetsSummary.bad.toString();
                    } else if (row.targetsSummary?.warning > 0) {
                        return row.targetsSummary.warning.toString();
                    } else if (row.targetsSummary?.muted_bad > 0) {
                        return row.targetsSummary.muted_bad.toString();
                    } else if (row.targetsSummary?.disabled > 0) {
                        return row.targetsSummary.disabled.toString();
                    } else {
                        return row.targetsSummary?.pending > 0 ? row.targetsSummary.pending.toString() : "-";
                    }
                },
                sortBy: row => row._frontData?.sortableTargets ?? "",
                valueToExport: row => {
                    let targets = "";
                    if (row.targetsSummary?.good) targets += "Ok(" + row.targetsSummary.good + ") ";
                    if (row.targetsSummary?.bad) targets += "Error(" + row.targetsSummary.bad + ") ";
                    if (row.targetsSummary?.warning) targets += "Warning(" + row.targetsSummary.warning + ") ";
                    if (row.targetsSummary?.muted_bad) targets += "Muted(" + row.targetsSummary.muted_bad + ") ";
                    if (row.targetsSummary?.disabled) targets += "Disabled(" + row.targetsSummary.disabled + ") ";
                    if (row.targetsSummary?.pending) targets += "Disabled(" + row.targetsSummary.pending + ") ";
                    return targets;
                },
                ...customizeColumn(overrides?.targets_summary)
            },
            {
                header: this.translate.instant("ALT_PATH"),
                columnDef: ChannelsColumnsDefTypes.ALT_PATH,
                width: 120,
                visible: false,
                component: ZxNgbHighlightComponent,
                assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<ChannelTypes>>(
                    row =>
                        this.channelsService.getDisasterRecoveryState(row) === RecoveryState.alternative
                            ? "Active"
                            : "Inactive",
                    row =>
                        this.channelsService.getDisasterRecoveryState(row) === RecoveryState.alternative
                            ? "Active"
                            : "Inactive",
                    () => true
                ),
                sortBy: (row: KeyMap<ChannelTypes>) =>
                    this.channelsService.getDisasterRecoveryState(row) === RecoveryState.alternative
                        ? "Active"
                        : "Inactive",
                textValue: (row: KeyMap<ChannelTypes>) =>
                    this.channelsService.getDisasterRecoveryState(row) === RecoveryState.alternative
                        ? "Active"
                        : "Inactive",
                columnFilterType: ColumnFilterType.SELECT,
                columnSelectOptions: ["Active", "Inactive"],
                columnFilterValue: (row: KeyMap<ChannelTypes>) =>
                    this.channelsService.getDisasterRecoveryState(row) === RecoveryState.alternative
                        ? "Active"
                        : "Inactive",
                ...customizeColumn(overrides?.alternative)
            },
            {
                header: this.translate.instant("TAGS"),
                columnDef: ChannelsColumnsDefTypes.TAGS,
                width: 160,
                visible: false,
                component: ZxTagsListComponent,
                assignComponentsInputs: (
                    zecComponentRef: ComponentRef<ZxTagsListComponent>,
                    row: KeyMap<ChannelTypes>,
                    searchTerm: string[]
                ) => {
                    const zecCompRef = zecComponentRef.instance;
                    zecCompRef.model = row;
                    zecCompRef.searchTerm = searchTerm.toString();
                },
                textValue: row => row.resourceTags?.map(t => t.name).join(", ") ?? "",
                sortBy: row => row.resourceTags?.map(t => t.name).join("") ?? "",
                valueToExport: row => row.resourceTags?.map(t => t.name).join(", ") ?? "",
                columnFilterType: ColumnFilterType.TAGS,
                columnFilterValue: row =>
                    row.resourceTags?.map(t => t.id).filter((id): id is number => id !== undefined),
                ...customizeColumn(overrides?.access_tags)
            },
            {
                header: this.translate.instant("PRIORITY"),
                columnDef: ChannelsColumnsDefTypes.PRIORITY,
                width: 80,
                visible: false,
                component: IconColumnComponent,
                assignComponentsInputs: (
                    componentRef: ComponentRef<IconColumnComponent>,
                    row: KeyMap<ChannelTypes>
                ) => {
                    const componentInstance = componentRef.instance;
                    componentInstance.iconType = IconTypes.CHECK;
                    componentInstance.showIcon = this.tagsService.isObjectVip(row.resourceTags);
                },
                sortBy: (row: ChannelTypes) => (this.tagsService.isObjectVip(row.resourceTags) ? "priority" : ""),
                textValue: (row: ChannelTypes) => (this.tagsService.isObjectVip(row.resourceTags) ? "priority" : ""),
                columnFilterType: ColumnFilterType.SELECT,
                columnSelectOptions: ["Yes", "No"],
                columnFilterValue: row => (this.tagsService.isObjectVip(row.resourceTags) ? "Yes" : "No"),
                ...customizeColumn(overrides?.priority)
            },
            {
                header: this.translate.instant("MUTED"),
                columnDef: "muted",
                width: 0,
                visible: false,
                textValue: row => (row.active_mute ? "muted" : ""),
                sortBy: row => (row.active_mute ? "muted" : ""),
                columnFilterType: ColumnFilterType.SELECT,
                columnSelectOptions: ["Yes", "No"],
                columnFilterValue: row => (row.active_mute ? "Yes" : "No"),
                ...customizeColumn(overrides?.muted)
            }
        ];
    }
}
