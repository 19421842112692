import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { BehaviorSubject, Subscription, combineLatest, firstValueFrom } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { TargetsService } from "../../../../pages/targets/targets.service";
import {
    AnyTarget,
    PublishingTarget,
    UdpRtpTarget,
    RtmpPushTarget,
    ZixiPullTarget,
    ZixiPushTarget,
    RistTarget,
    MediaLiveHttpTarget,
    SrtTarget,
    ChannelTypes
} from "../../../../pages/channels/channel";
import { ErrorService } from "src/app/components/error/error.service";
import { SourcePreferenceSelectionValues } from "../../zx-delivery-channel-source-select/zx-delivery-channel-source-select.component";
import { TranscodingProfile } from "../../../../pages/transcoding-profiles/transcoding-profile";
import { ChannelsService } from "src/app/pages/channels/channels.service";
import { ChannelsDefinitions } from "src/app/pages/channels/channels.definitions";

type DeliveryChannelTarget = UdpRtpTarget | RistTarget | RtmpPushTarget | ZixiPullTarget | ZixiPushTarget | SrtTarget;
const SourceChannelTypeValues = ["adaptive", "mediaconnect", "delivery", "medialive"] as const;
export type SourceChannelType = (typeof SourceChannelTypeValues)[number];

@Component({
    selector: "app-switch-channel-dialog",
    templateUrl: "./switch-channel-dialog.component.html"
})
export class SwitchChannelDialogComponent implements OnInit, OnDestroy {
    targets: AnyTarget[];
    type: SourceChannelType;

    adaptiveChannelID: number | null = null;
    deliveryChannelID: number | null = null;
    mediaconnectFlowID: number | null = null;
    medialiveChannelID: number | null = null;

    preferredSourceID: number = SourcePreferenceSelectionValues.previousSelection;
    transcodeProfile: TranscodingProfile | null;

    canMediaconnect: boolean;
    canMedialive: boolean;
    saving = false;
    state = "default";
    forceSameBX = false;
    canReuseBX = false;
    haveAssignedTargets = false;
    havePullTargets = false;

    private routeSubscription: Subscription;
    private channelsBS$ = new BehaviorSubject<ChannelTypes[]>([]);
    public activeModal = inject(NgbActiveModal);
    private router = inject(Router);
    private targetsService = inject(TargetsService);
    private channelsService = inject(ChannelsService);
    private channelsDefinitions = inject(ChannelsDefinitions);
    private errorService = inject(ErrorService);

    tableColumnsSchema = this.channelsDefinitions.getTableColumnsSchema({
        name: { width: 280 },
        sources: { visible: false },
        type: { visible: false },
        targets_summary: { visible: false }
    });

    selectedChannel: ChannelTypes;
    channels: ChannelTypes[];
    selectedRows: ChannelTypes[] = [];
    loadingChannels = true;

    constructor() {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    async ngOnInit() {
        // Reset
        this.targets.map(t => {
            t._frontData.saving = false;
            t._frontData.processing = "";
            t._frontData.hasError = false;
        });

        const canMediaConnect = this.targets.reduce((canMediaConnect, t) => canMediaConnect && t.mediaconnect, true);
        if (canMediaConnect) this.canMediaconnect = canMediaConnect;

        const canMediaLive = this.targets.reduce((canMedialive, t) => canMedialive && t.medialive, true);
        if (canMediaLive) this.canMedialive = canMediaLive;

        if (this.targets[0].target instanceof PublishingTarget) {
            this.adaptiveChannelID = this.targets[0].target.adaptive_channel_id;
            this.type = "adaptive";
        } else if (
            this.targets[0].target instanceof UdpRtpTarget ||
            this.targets[0].target instanceof RistTarget ||
            this.targets[0].target instanceof RtmpPushTarget ||
            this.targets[0].target instanceof ZixiPullTarget ||
            this.targets[0].target instanceof ZixiPushTarget
        ) {
            if (this.canMediaconnect && this.targets[0].target.mediaconnect_flow_id != null) {
                this.type = "mediaconnect";
                this.mediaconnectFlowID = this.targets[0].target.mediaconnect_flow_id;
            } else if (this.canMedialive && this.targets[0].target.medialive_channel_id != null) {
                this.type = "medialive";
                this.medialiveChannelID = this.targets[0].target.medialive_channel_id;
                this.transcodeProfile = this.targets[0].target.transcodingProfile;
            } else {
                this.type = "delivery";
                this.deliveryChannelID = this.targets[0].target.delivery_channel_id;
            }
        } else if (this.targets[0].target instanceof MediaLiveHttpTarget) {
            this.type = "medialive";
            this.medialiveChannelID = this.targets[0].target.medialive_channel_id;
        } else if (this.targets[0].target instanceof SrtTarget) {
            if ((this.targets[0].target as SrtTarget).mediaconnect_flow_id) {
                this.mediaconnectFlowID = this.targets[0].target.mediaconnect_flow_id;
                this.type = "mediaconnect";
            } else {
                this.deliveryChannelID = this.targets[0].target.delivery_channel_id;
                this.type = "delivery";
            }
        }

        this.haveAssignedTargets = this.targets.some(t => t.target.deliveryChannel);
        this.canReuseBX = this.targets.some(t => !t.pull && !t.ndi);
        this.havePullTargets = this.targets.some(t => t.pull);
        if (this.targets.length === 1 && this.type === "delivery") {
            const deliveryTarget: DeliveryChannelTarget = this.targets[0].target as DeliveryChannelTarget;
            this.preferredSourceID = deliveryTarget.preferred_source;
        }

        setTimeout(() => this.getChannels());
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    async getChannels() {
        this.loadingChannels = true;

        if (this.type === "adaptive") {
            this.channels = await firstValueFrom(this.channelsService.getAdaptiveChannels());
            if (this.adaptiveChannelID) {
                const channelID = this.channels.find(c => c.id === this.adaptiveChannelID);
                if (channelID) this.selectedRows = [channelID];
            }
        }
        if (this.type === "delivery") {
            await firstValueFrom(this.channelsService.getDeliveryChannels());
            await firstValueFrom(this.channelsService.getFailoverChannels());

            const channels$ = combineLatest([
                this.channelsService.deliveryChannels,
                this.channelsService.failoverChannels.pipe(map(channels => channels.map(ch => ch.deliveryChannel)))
            ]).pipe(
                map(([deliveryChannels, failoverDeliveryChannels]) =>
                    (deliveryChannels ?? []).filter(ch => !ch.is_hidden).concat(failoverDeliveryChannels ?? [])
                )
            );
            channels$.pipe(take(1)).subscribe(channels => {
                this.channels = channels;
            });
            if (this.deliveryChannelID) {
                const channelID = this.channels.find(c => c.id === this.deliveryChannelID);
                if (channelID) this.selectedRows = [channelID];
            }
        }
        if (this.type === "mediaconnect") {
            this.channels = await firstValueFrom(this.channelsService.getMediaConnectFlows());
            if (this.mediaconnectFlowID) {
                const channelID = this.channels.find(c => c.id === this.mediaconnectFlowID);
                if (channelID) this.selectedRows = [channelID];
            }
        }
        if (this.type === "medialive") {
            this.channels = await firstValueFrom(this.channelsService.getMediaLiveChannels());
            if (this.medialiveChannelID) {
                const channelID = this.channels.find(c => c.id === this.medialiveChannelID);
                if (channelID) this.selectedRows = [channelID];
            }
        }

        this.prepTableData();
        this.loadingChannels = false;
    }

    typeChanged() {
        this.selectedRows = [];
        this.channels = [];
        this.prepTableData();
        this.getChannels();
    }

    prepTableData() {
        this.channelsBS$.next(this.channels);
    }

    onSort(s: string) {
        this.channelsDefinitions.currentTableSortDirection = s;
    }

    selectRow = (channel: ChannelTypes): void => {
        this.selectedChannel = channel;
    };

    get allChannels() {
        return this.channelsBS$;
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            adaptive_channel_id: this.type === "adaptive" ? this.selectedRows[0]?.id ?? null : null,
            delivery_channel_id: this.type === "delivery" ? this.selectedRows[0]?.id ?? null : null,
            mediaconnect_flow_id: this.type === "mediaconnect" ? this.selectedRows[0]?.id ?? null : null,
            medialive_channel_id: this.type === "medialive" ? this.selectedRows[0]?.id ?? null : null,
            force_same_bx: this.forceSameBX,
            preferred_source: <undefined | number>undefined,
            transcoding_profile_id:
                this.type === "medialive" && this.transcodeProfile && this.selectedRows[0]?.id
                    ? this.transcodeProfile.id
                    : undefined
        };

        for (const target of this.targets) {
            target._frontData.saving = true;
            target._frontData.processing = "start";
            if (this.type === "delivery") {
                if (this.preferredSourceID !== SourcePreferenceSelectionValues.previousSelection)
                    model.preferred_source = this.preferredSourceID;
                model.force_same_bx =
                    this.forceSameBX && !!target.target.deliveryChannel && !target.pull && !target.ndi;
            }
            const result = await this.targetsService.updateTarget(target.target, model);
            if (result !== false) {
                target._frontData.hasError = false;
            } else {
                this.errorService.currentHttpErrorResponse.pipe(take(1)).subscribe(errorResponse => {
                    target._frontData.error = {
                        status: errorResponse.status,
                        statusText: errorResponse.statusText,
                        message: errorResponse.error.error
                    };
                });

                target._frontData.hasError = true;
            }

            target._frontData.saving = false;
            target._frontData.processing = "end";
        }

        this.state = "done";
        this.saving = false;
    }
}
