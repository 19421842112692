// BG Color
const SCTE_35_SEGMENTATION_TYPE_ID_TO_COLOR = {
    "1": "#7EB26D",
    "16": "#BF1B00",
    "17": "#D21F01",
    "19": "#979A9A",
    "20": "#B3B6B7",
    "32": "#F29191",
    "33": "#F29191",
    "34": "#3D85C6",
    "35": "#6FA8DC",
    "48": "#F4D598",
    "49": "#F4D598",
    "50": "#EAB839",
    "51": "#EAB839",
    "52": "#F4D598",
    "53": "#F4D598",
    "54": "#EAB839",
    "55": "#EAB839",
    "60": "#705DA0",
    "61": "#8871C5",
    "62": "#806EB7",
    "63": "#614D93"
};
// Text Color
const SCTE_35_SEGMENTATION_TYPE_ID_TO_TEXTCOLOR = {
    "1": "#000000",
    "16": "#FFFFFF",
    "17": "#FFFFFF",
    "19": "#000000",
    "20": "#000000",
    "32": "#000000",
    "33": "#000000",
    "34": "#000000",
    "35": "#000000",
    "48": "#000000",
    "49": "#000000",
    "50": "#000000",
    "51": "#000000",
    "52": "#000000",
    "53": "#000000",
    "54": "#000000",
    "55": "#000000",
    "60": "#FFFFFF",
    "61": "#000000",
    "62": "#000000",
    "63": "#FFFFFF"
};

const SPLICE_INSERT_INTO_OUT_COMMAND_TYPE = ["Splice into network", "Splice out of network"];

function getStyle(segmentationTypeId: number | undefined, segType: string | undefined) {
    if (!segmentationTypeId) return "";
    const hexColorBySegmentationType = SCTE_35_SEGMENTATION_TYPE_ID_TO_COLOR[segmentationTypeId];
    const textColorBySegmentationType = SCTE_35_SEGMENTATION_TYPE_ID_TO_TEXTCOLOR[segmentationTypeId];
    if (hexColorBySegmentationType) {
        return `background-color: ${hexColorBySegmentationType} !important; color: ${textColorBySegmentationType} !important`;
    }
    if (!segType) return "";
    const isHexColorBySpliceIntoOutCommandType = SPLICE_INSERT_INTO_OUT_COMMAND_TYPE.includes(segType);
    if (isHexColorBySpliceIntoOutCommandType) {
        return `background-color: #F9934E !important; color: #000000 !important;`;
    }
    return "";
}

export const scte35ColorsService = { getStyle };
