import { Component, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";

export type FailoverErrorConcealmentData = {
    error_concealment: 0 | 1 | boolean;
    error_concealment_continuous_timeline: 0 | 1 | boolean;
    error_concealment_replace_frames: 0 | 1 | boolean;
    error_concealment_delay_ms: number;
    error_concealment_cbr_padding_kbps: number | null;
    error_concealment_cbr_padding_pcr_interval_ms: 40 | 100;
    error_concealment_fix_cc: 0 | 1 | boolean;
};

@Component({
    selector: "app-failover-error-concealment-form",
    templateUrl: "./error-concealment.component.html"
})
export class FailoverErrorConcealmentFormComponent implements OnChanges {
    @Input() model: FailoverErrorConcealmentData;
    @Input() isEdit: boolean;

    @Output() modelChange = new EventEmitter();

    showErrorConcealment: boolean | null = null;

    pcrIntervalOptions = [
        { value: 100, label: "ATSC [100ms]" },
        { value: 40, label: "DVB [40ms]" }
    ];

    async ngOnChanges() {
        if (this.showErrorConcealment == null && !!this.model.error_concealment) this.showErrorConcealment = true;
    }

    onChange() {
        this.modelChange.emit(this.model);
    }
}
