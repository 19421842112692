import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedPageService, SharedResourcePermissions, SharedPaged } from "../../../../services/shared-page.service";
import { firstValueFrom } from "rxjs";
import { ClipboardService } from "ngx-clipboard";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../../constants/constants";
import { SharedResource } from "../../../../models/shared";

export interface CreateConfigs {
    sharedPageRoute: string;
    refreshFunction: () => void;
    objectTranslateTitle: string;
    mainObjectId: number;
    mainObjectType: string;
    permissions: SharedResourcePermissions[];
    isCreateMode: boolean;
}

export interface RegeneratePasswordConfigs {
    sharedResource: SharedResource;
    isRegeneratePasswordMode: boolean;
}

@Component({
    selector: "app-share-page-dialog",
    templateUrl: "./share-page-dialog.component.html"
})
export class SharePageDialogComponent implements OnInit {
    dialogConfigs: CreateConfigs | RegeneratePasswordConfigs;

    form: FormGroup<{
        name: FormControl<string | null>;
        startTime: FormControl<string | null>;
        endTime: FormControl<string | null>;
    }>;
    isFormSubmitted = false;
    isSaving = false;
    error: string | null = null;
    url: string | null = null;
    password: string | null = null;

    constructor(
        public activeModal: NgbActiveModal,
        private sharedPageService: SharedPageService,
        public clipboardService: ClipboardService,
        private translateService: TranslateService
    ) {}

    get isCreateMode(): boolean {
        return Boolean((<CreateConfigs>this.dialogConfigs).isCreateMode);
    }

    get isRegeneratePasswordMode(): boolean {
        return Boolean((<RegeneratePasswordConfigs>this.dialogConfigs).isRegeneratePasswordMode);
    }

    get dialogName(): string {
        if (this.isCreateMode) {
            return `${this.translateService.instant("SHARE")} ${this.translateService.instant(
                (<CreateConfigs>this.dialogConfigs).objectTranslateTitle
            )}`;
        }
        if (this.isRegeneratePasswordMode) {
            return this.translateService.instant("REGENERATE_PASSWORD");
        }
    }

    get isActionDone(): boolean {
        if (this.isCreateMode) {
            return Boolean(this.url && this.password);
        }
        if (this.isRegeneratePasswordMode) {
            return Boolean(this.password);
        }
    }

    ngOnInit(): void {
        if (this.isCreateMode) {
            const now = new Date();
            this.form = new FormGroup({
                name: new FormControl("", [Validators.required]),
                startTime: new FormControl(now.toISOString()),
                endTime: new FormControl(new Date(now.valueOf() + Constants.HOUR).toISOString(), [Validators.required])
            });
        }
    }

    async onSubmit() {
        this.isSaving = true;
        if (this.isCreateMode) {
            await this.submitCreate();
        }
        if (this.isRegeneratePasswordMode) {
            await this.submitRegeneratePassword();
        }
        this.isSaving = false;
    }

    async submitCreate() {
        this.isFormSubmitted = true;
        if (this.form.invalid) return;
        const { sharedPageRoute, mainObjectId, mainObjectType, permissions, refreshFunction } = <CreateConfigs>(
            this.dialogConfigs
        );
        const { result, error } = await firstValueFrom(
            this.sharedPageService.generateSharedPage(
                sharedPageRoute,
                this.form.value.name,
                mainObjectId,
                mainObjectType,
                this.form.value.startTime,
                this.form.value.endTime,
                permissions
            )
        );
        if (result) {
            this.url = result.url;
            this.password = result.password;
            this.error = null;
            this.form.controls.startTime.disable();
            this.form.controls.endTime.disable();
            this.form.controls.name.disable();
            await refreshFunction();
        }
        if (error) this.error = error;
    }

    async submitRegeneratePassword() {
        const sharedResource = (<RegeneratePasswordConfigs>this.dialogConfigs).sharedResource;
        const { result, error } = await firstValueFrom(this.sharedPageService.regeneratePassword(sharedResource));
        if (result) {
            this.password = result.password;
            this.error = null;
        }
        if (error) this.error = error;
    }
}
