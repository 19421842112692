import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";

@Component({
    selector: "app-mini-form-wrapper",
    template: `
        <div class="container-fluid">
            <div class="row">
                <div class="col mt-3 mb-3">
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-6">
                                <ng-content></ng-content>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button
                                    zmid="form-submit"
                                    type="submit"
                                    class="btn btn-primary me-2"
                                    [disabled]="saving"
                                    (click)="submitted.emit()"
                                >
                                    {{ "UPDATE" | translate }}
                                </button>
                                <span *ngIf="saving">{{ "SAVING" | translate }}...</span>
                                <span *ngIf="saved">{{ "SAVED" | translate }}!</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    `
})
export class MiniFormWrapperComponent implements OnInit {
    @Input() saving = false;
    @Input() saved = false;
    @Output() submitted = new EventEmitter();

    private controlContainer = inject(ControlContainer);
    public form: FormGroup;

    ngOnInit() {
        if (this.controlContainer.control instanceof FormGroup) {
            this.form = this.controlContainer.control;
        } else {
            throw new Error("MiniFormWrapperComponent requires a parent FormGroup.");
        }
    }
}
